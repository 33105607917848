import React from "react";
import DevelopBannerSection from "../components/Homepage/DevelopPageComponents/DevelopBannerSection";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HealthSliderCard from "../components/HealthcarePageComponent/HealthSliderCard";
import IndusExpert from "../components/IndustriesPageComponents/IndusExpert";
import {
  float1,
  float2,
  keyImg1,
  keyImg10,
  keyImg11,
  keyImg7,
  keyImg8,
  keyImg9,
  mouseSlide18,
  mouseSlide19,
  vinayakprofileImg,
} from "../components";
import DevDiff from "../components/Homepage/DevelopPageComponents/DevDiff";
import HealthCareTabSection from "../components/HealthcarePageComponent/HealthCareTabSection";
import HealthcareTabs from "../components/Tabs/HealthcareTabs";
import ExpertButton from "../components/CTA/ExpertButton";
import ScrollingSlide from "../components/MouseScrollingComponent/ScrollingSlide";
function Insurance() {
  var settings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: true,
    speed: 800,
    adaptiveHeight: false,
    infinite: true,
    useTransform: true,
    cssEase: "cubic-bezier(0.77, 0, 0.18, 1)",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section id="healthCare" className="healthCare insuranceBg">
      <div className="devTopSection ">
        <DevelopBannerSection
          line1="Revolutionizing"
          line2="Insurance Sector "
          line3="Through IT Solutions"
          BannerPara="In our rapidly evolving landscape, insurance firms must adopt contemporary solutions to outpace competitors and cater to dynamic customer demands. Outdated methods reliant on manual procedures are swiftly losing relevance, compelling insurers to welcome cutting-edge technologies like AI, IoT, and big data."
        />
        <div className="shapeSvg">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15002 2408">
            <g id="Layer_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <path
                className="fil0"
                d="M14999.66 160.41l0 2250.5 -15002 0 0 -2408.48c4.48,15.46 34.05,38.31 47.09,51.36 18.3,18.3 33.31,33.31 51.61,51.61l377.15 322.94c339.34,254.69 723.38,451.6 1129.12,576.25 740.24,227.38 1518.28,209.44 2250.74,-34.78 297.55,-99.2 684.02,-289.57 933.07,-476.11 87.62,-65.63 227.27,-165.69 307.34,-240.17 31.42,-29.23 114.35,-99.39 131.22,-124.58 20,4.66 99.59,47.55 124.17,59.83 42.41,21.2 81.55,40.72 122.67,61.33l125.61 58.39c133.82,59.52 249.76,112.63 391.76,169.22 238.34,95 587.91,216.82 832.54,284.94 200.63,55.86 382.28,106.97 590.69,154.3 1557.59,353.7 3775.68,425.94 5382.01,17.95 25.54,-6.48 49.29,-10.39 73.59,-16.17l373.43 -93.31c198.88,-54.19 343.18,-97.87 536.61,-159.02l312.66 -109.2c47.88,-17.88 90.5,-33.57 136.53,-51.96 82.88,-33.12 316.41,-125.5 392.57,-163.92l128.78 -59.7c23.6,-10.77 225.99,-115.13 251.04,-121.22z"
              />
            </g>
          </svg>
        </div>
      </div>
      <div className="heighlightsSection">
        <div className="container">
          <div className="sectionTitle">
            <h2>Crucial Highlights</h2>
            <p>5-Year Evolution of the Insurance Transformation</p>
          </div>
          <div className="row">
            <div className="col-md-4 highlightsBox">
              <h5>$42.7 Billion</h5>
              <p>
                Anticipated by analysts, the global IoT insurance market is
                poised to achieve its milestone by 2027
              </p>
            </div>
            <div className="col-md-4 highlightsBox">
              <h5>75%</h5>
              <p>
                In the perspective of insurance leaders, the industry envisions
                a transformation within a span of 3 years
              </p>
            </div>
            <div className="col-md-4 highlightsBox">
              <h5>80%</h5>
              <p>
                Policyholders are embracing digital platforms for their
                insurance interactions
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="healthServicesSliderWrap retailsServicesSection">
        <div className="container">
          <div className="row sectionFlexHeading mb-5">
            <div className="col-md-8 col-12 mx-auto">
              <div className="sectionTitle text-center">
                <h2>Demand</h2>
                <p className="sectionPara text-center">
                  Revolutionizing Insurance Enterprises through Technological
                  Advancements
                </p>
              </div>
            </div>
          </div>
          <div className="healthServicesSlider">
            <Slider {...settings} className="slider-with-gap retailSlider">
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Insurtech Solutions Development"
                  cardPara="There's a growing demand for innovative technology solutions that can reshape various aspects of the insurance industry, such as claims processing, policy management, customer interactions, and risk assessment."
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Customer-Centric Digital Platforms"
                  cardPara="There's a demand for user-friendly digital platforms that allow policyholders to manage their policies, file claims, and interact with insurers seamlessly, enhancing customer satisfaction and loyalty."
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Data Analytics and Predictive Modeling"
                  cardPara="Businesses that can offer sophisticated data analytics tools to help insurers understand customer behavior, market trends, and risk patterns better are in demand."
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Cybersecurity for Insurers"
                  cardPara="With the increasing threat of cyberattacks, there's a demand for cybersecurity solutions specifically tailored to protect sensitive insurance data and prevent breaches."
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="AI-Powered Chatbots and Virtual Assistants"
                  cardPara="Building AI-driven chatbots and virtual assistants that can provide instant customer support, answer policy-related questions, and guide clients through the insurance process."
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Personalized Insurance Products"
                  cardPara="Businesses that can leverage technology to offer personalized insurance products based on individual customer profiles, behavior, and needs."
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <section id="healthCareTabSection">
        <div className="container">
          <HealthCareTabSection
            tabSectionTitle1="Enhancing Insurance with"
            tabSectionTitle2="Innovative "
            tabSectionTitle3="Technology"
            tabSectionPara="Discover a comprehensive suite of cutting-edge Insurance IT and Software Solutions tailored to redefine and optimize your business operations. Our offerings cater to the diverse needs of Insurance Agencies, Providers, Agents, Carriers, and Brokers."
          />
          <HealthcareTabs
            tabTitle1="Customer Relationship Management (CRM) Software"
            tabTitle2="Underwriting Software"
            tabTitle3="Policy Administration Software"
            tabTitle4="Claims Management Software"
            tabContentTitle11="Customer Relationship Management (CRM) Software"
            contentList11="Contact Management"
            contentList12="Sales Automation"
            contentList13="Marketing Campaigns"
            contentList14="Customer Service"
            contentList15="Analytics and Reporting"
            contentList16="Integration Capabilities"
            tabContentTitle22="Underwriting Software"
            contentList21="Automated Risk Assessment"
            contentList22="Data Integration"
            contentList23="Predictive Analytics"
            contentList24="Customizable Rules"
            contentList25="Workflow Automation"
            contentList26="Document Management"
            tabContentTitle33="Policy Administration Software"
            contentList31="Storefront Design and Customization"
            contentList32="Policy Creation and Issuance"
            contentList33="Automated Underwriting"
            contentList34="Quoting and Rating"
            contentList35="Endorsement Management"
            contentList36="Renewal Processing"
            contentList37="Billing and Premium Management"
            contentList38="Agent and Customer Portals"
            tabContentTitle44="Claims Management Software"
            contentList41="Order Processing"
            contentList42="Claim Submission"
            contentList43="Automated Workflow"
            contentList44="Real-time Tracking"
            contentList45="Fraud Detection"
            contentList46="Communication Tools"
            contentList47="Analytics and Reporting"
          />
        </div>
      </section>
      <section className="keyAdvantagesSection">
        <div className="container">
          <div className="sectionTitle">
            <h2>Key Advantages</h2>
            <p>All important features to learn anywhere, anytime online</p>
          </div>
          <div className="row gy-5">
            <div className="col-md-4">
              <div className="keyBenifits">
                <div className="keyImgWrap">
                  <img src={keyImg1} alt="Enhanced Operational Efficiency" />
                </div>
                <h5>Enhanced Operational Efficiency</h5>
                <p>
                  The implementation of automation in policy issuance,
                  underwriting, and claims processing has the potential to
                  significantly elevate the operational efficiency and velocity
                  of insurance firms.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="keyBenifits">
                <div className="keyImgWrap">
                  <img src={keyImg7} alt="Elevating Customer Satisfaction" />
                </div>
                <h5>Elevating Customer Satisfaction</h5>
                <p>
                  Technologies such as mobile applications, online portals, and
                  chatbots have the capability to enhance interactions between
                  insurers and customers, thereby improving the overall customer
                  experience.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="keyBenifits">
                <div className="keyImgWrap">
                  <img src={keyImg8} alt="Enhanced Risk Evaluation" />
                </div>
                <h5>Enhanced Risk Evaluation</h5>
                <p>
                  Utilizing data analytics tools enables insurers to effectively
                  evaluate risks and pinpoint trends, leading to more informed
                  underwriting choices.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="keyBenifits">
                <div className="keyImgWrap">
                  <img src={keyImg9} alt="" />
                </div>
                <h5>Strategic Edge</h5>
                <p>
                  The incorporation of novel technologies and tools empowers
                  insurers to maintain relevance, competitiveness, and draw in
                  fresh clientele, thus gaining a distinct competitive
                  advantage.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="keyBenifits">
                <div className="keyImgWrap">
                  <img src={keyImg10} alt="Boosting Financial Efficiency" />
                </div>
                <h5>Boosting Financial Efficiency</h5>
                <p>
                  By automating tasks and minimizing manual labor, insurers can
                  enhance their financial performance, leading to reduced
                  operational costs and an improved bottom line.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="keyBenifits">
                <div className="keyImgWrap">
                  <img src={keyImg11} alt="Fortified Security Measures" />
                </div>
                <h5>Fortified Security Measures</h5>
                <p>
                  Through the implementation of encryption and multi-factor
                  authentication, insurers can reinforce the safeguarding of
                  customer data, effectively thwarting breaches and enhancing
                  overall security.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
      className="mouseScrollingSliderSection"
      id="mouseScrollingSliderSection"
    >
      <div className="container">
        <div className="sectionTitle">
          <h2>
            {" "}
            Work <span className="textGrade">Portfolio</span>
          </h2>
          <ExpertButton
            rText="Get Project Quote"
            rName="rAnimeBtn qouteBtn"
          />
        </div>
      </div>
      <ScrollingSlide
        scrollSliderImg1={mouseSlide18}
        scrollSliderImg2={mouseSlide19}
      />
    </section>
      <section className="IndusExpertSection">
        <IndusExpert
          cName="Version Next Technologies"
          cSecTitle="Why Hire Version Next Technologies as Branding Partner?"
          profileName="Mr. Vinayak Parab"
          designation="Buisness Head"
          cardTitle1="Innovation"
          cardData1="We brings innovative solutions that leverage cutting-edge technologies to drive business growth and stay ahead of the competition."
          cardTitle2="Integrity"
          cardData2="We uphold the highest standards of integrity, ensuring transparency, honesty, and ethical practices in all our interactions with clients."
          cardTitle3="Customer-Obsession"
          cardData3="Our unwavering focus on delivering exceptional customer experiences sets us apart, as we go above and beyond to meet and exceed client expectations."
          cardTitle4="Quality"
          cardData4="We are committed to delivering top-notch quality in all our services, adhering to industry best practices and rigorous quality control processes to ensure excellence."
          profileImg={vinayakprofileImg}
          linkdinLink="http://linkedin.com/in/vinayak-parab-2a7a8615"
          linkdinIcon="fab fa-linkedin"
        />
      </section>
      <section className="whatMakesSection" id="whatMakesSection">
        <DevDiff
          float1={float1}
          float2={float2}
          Char1="What Makes"
          Char2="us"
          Char3="Different?"
          diffDescription="What sets us apart is our expertise, customization, collaboration, creativity, results-driven approach, and customer-centric focus. We have a highly skilled team, personalized solutions, open communication, innovative ideas, a focus on measurable results, and a dedication to customer satisfaction. These factors combine to deliver outstanding outcomes and exceptional experiences for our clients."
        />
      </section>
    </section>
  );
}

export default Insurance;
