import React from "react";
import DevelopBannerSection from "../components/Homepage/DevelopPageComponents/DevelopBannerSection";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HealthSliderCard from "../components/HealthcarePageComponent/HealthSliderCard";
import IndusExpert from "../components/IndustriesPageComponents/IndusExpert";
import { float1, float2, sanyogprofileImg } from "../components";
import DevDiff from "../components/Homepage/DevelopPageComponents/DevDiff";
import HealthCareTabSection from "../components/HealthcarePageComponent/HealthCareTabSection";
import HealthcareTabs from "../components/Tabs/HealthcareTabs";
function BlockChain() {
  var settings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: true,
    speed: 800,
    adaptiveHeight: false,
    infinite: true,
    useTransform: true,
    cssEase: "cubic-bezier(0.77, 0, 0.18, 1)",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section id="healthCare" className="healthCare blockChainBg">
      <div className="devTopSection">
        <DevelopBannerSection
          line1="Blockchain Applications"
          line2="Across Diverse"
          line3="Industry Sectors"
          BannerPara="What is the current influence of blockchain on finance, identity, supply chain management, and other industries? Discover how leading sectors are implementing Ethereum blockchain solutions to address recurring industry challenges."
        />
        <div className="shapeSvg">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15002 2408">
            <g id="Layer_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <path
                className="fil0"
                d="M14999.66 160.41l0 2250.5 -15002 0 0 -2408.48c4.48,15.46 34.05,38.31 47.09,51.36 18.3,18.3 33.31,33.31 51.61,51.61l377.15 322.94c339.34,254.69 723.38,451.6 1129.12,576.25 740.24,227.38 1518.28,209.44 2250.74,-34.78 297.55,-99.2 684.02,-289.57 933.07,-476.11 87.62,-65.63 227.27,-165.69 307.34,-240.17 31.42,-29.23 114.35,-99.39 131.22,-124.58 20,4.66 99.59,47.55 124.17,59.83 42.41,21.2 81.55,40.72 122.67,61.33l125.61 58.39c133.82,59.52 249.76,112.63 391.76,169.22 238.34,95 587.91,216.82 832.54,284.94 200.63,55.86 382.28,106.97 590.69,154.3 1557.59,353.7 3775.68,425.94 5382.01,17.95 25.54,-6.48 49.29,-10.39 73.59,-16.17l373.43 -93.31c198.88,-54.19 343.18,-97.87 536.61,-159.02l312.66 -109.2c47.88,-17.88 90.5,-33.57 136.53,-51.96 82.88,-33.12 316.41,-125.5 392.57,-163.92l128.78 -59.7c23.6,-10.77 225.99,-115.13 251.04,-121.22z"
              />
            </g>
          </svg>
        </div>
      </div>
      <div className="healthServicesSliderWrap">
        <div className="container">
          <div className="row sectionFlexHeading">
            <div className="col-md-6 col-12">
              <div className="sectionTitle">
                <h2>
                  What are the Practical Applications and Use Cases of
                  Blockchain Technology?
                </h2>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <p className="sectionPara">
                The fundamental attributes of blockchain technology encompass
                decentralization, transparency, immutability, and automation.
                These fundamental qualities find application across numerous
                industries, giving rise to a wide range of practical use cases.
                Below, we highlight what we consider to be the most relevant
                blockchain applications for businesses, organizations, and
                governmental entities.
              </p>
            </div>
          </div>
          <div className="healthServicesSlider">
            <Slider {...settings} className="slider-with-gap">
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Capital Markets"
                  cardPara="Blockchain in capital markets offers easier, cheaper, and faster access to capital, reducing barriers, enabling peer-to-peer trading, and improving transparency, while also lowering costs and risks, and streamlining auditing and compliance."
                  cardLink=""
                  btnName="Get consultation"
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Decentralized Finance (DeFi)"
                  cardPara="DeFi represents a transition from centralized finance to peer-to-peer finance using Ethereum-based decentralized technologies. It's revolutionizing financial access, opportunity, and trust with millions actively participating."
                  cardLink=""
                  btnName="Leave a request"
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Digital Identity"
                  cardPara="A blockchain-powered digital identity system offers a secure, unified infrastructure with benefits for enterprises, users, and IoT management, safeguarding against theft and enhancing data control for individuals."
                  cardLink=""
                  btnName="Order an estimate"
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Finance"
                  cardPara="Financial services face challenges like outdated operations, slow settlements, transparency issues, and security risks. Blockchain improves the digitization of financial instruments, boosting liquidity, cutting capital costs, and reducing counterparty risk."
                  cardLink=""
                  btnName="Leave a request"
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <section id="healthCareTabSection">
        <div className="container">
          <HealthCareTabSection
            tabSectionTitle1="Blockchain in Various"
            tabSectionTitle2="Industries"
            tabSectionTitle3=""
            tabSectionPara="Explore the transformative potential of blockchain technology in finance, identity management, and supply chain operations. Discover how Ethereum-based solutions are addressing key industry challenges."
          />
          <HealthcareTabs
            tabTitle1="Global Trade and Commerce"
            tabTitle3="Government and the Public Sector"
            tabTitle2="Healthcare and the Life Sciences"
            tabTitle4="Insurance"
            tabContentTitle11="Global Trade and Commerce"
            contentList11="Cross-Border Transactions"
            contentList12="Supply Chain Integration"
            contentList13="Trade Finance Innovation"
            contentList14="Regulatory Compliance"
            contentList15="Transparency and Traceability"
            contentList16="Cost Reduction"
            tabContentTitle33="Government and the Public Sector"
            contentList31="Governance and Accountability"
            contentList32="Digital Transformation"
            contentList33="Citizen Engagement"
            contentList34="Data Security"
            contentList35="Public Services Efficiency"
            contentList36="Blockchain Voting"
            tabContentTitle22="Healthcare and the Life Sciences"
            contentList21="Patient Care Enhancement"
            contentList22="Medical Records Security"
            contentList23="Health Data Interoperability"
            contentList24="Research Advancements"
            tabContentTitle44="Insurance"
            contentList41="Risk Assessment"
            contentList42="Premium Calculation"
            contentList43="Claims Processing"
            contentList44="Policy Management"
            contentList45="Fraud Detection"
            contentList46="Data Analytics"
          />
        </div>
      </section>
      <section className="IndusExpertSection newSection">
        <IndusExpert
          cName="Version Next Technologies"
          cSecTitle="VNT's Blockchain-Based Solutions"
          profileName="Mr. Sanyogg Shelar"
          designation="Buisness Head"
          cardTitle1="Innovative Solutions"
          cardData1="Our approach is rooted in innovation, ensuring your enterprise game software is at the forefront of technology."
          cardTitle2="Unparalleled Expertise"
          cardData2="With our seasoned team, we bring unmatched experience and skill to every aspect of game development."
          cardTitle3="Seamless Integration"
          cardData3="Our software seamlessly integrates with your existing systems, streamlining your enterprise operations."
          cardTitle4="Quality Assurance "
          cardData4="Our commitment to excellence guarantees a final product that meets the highest standards of quality."
          profileImg={sanyogprofileImg}
          facebookLink="https://profiles.wordpress.org/codexdemon/"
          facebookIcon="fab fa-wordpress"
          twiiterLink="https://twitter.com/sanyog"
          twitterIcon="fab fa-twitter-square"
          linkdinLink="https://www.linkedin.com/in/sanyogs"
          linkdinIcon="fab fa-linkedin"
        />
      </section>
      <section className="whatMakesSection" id="whatMakesSection">
        <DevDiff
          float1={float1}
          float2={float2}
          Char1="What Makes"
          Char2="us"
          Char3="Different?"
          diffDescription="What sets us apart is our expertise, customization, collaboration, creativity, results-driven approach, and customer-centric focus. We have a highly skilled team, personalized solutions, open communication, innovative ideas, a focus on measurable results, and a dedication to customer satisfaction. These factors combine to deliver outstanding outcomes and exceptional experiences for our clients."
        />
      </section>
    </section>
  );
}

export default BlockChain;
