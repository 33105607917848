import React, { useState, useEffect } from "react";
import { darkBluearrow, whitearrow } from "../..";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SyncSlider from "../DevelopPageComponents/SyncSlider";
import ExpertButton from "../../CTA/ExpertButton";
function Industries(props) {
  const [showResults, setShowResults] = useState(false);
  const [isIndus, setPage] = useState(false);
  const [isDev, isSetDev] = useState(false);
  const [isDigital, isSetDigital] = useState(false);
  const locationN = useLocation();
  useEffect(() => {
    if (locationN.pathname === "/industries") {
      setPage(true);
    } else if (locationN.pathname === "/mobile-app-development") {
      isSetDev(true);
    } else if (locationN.pathname === "/digital-marketing") {
      isSetDigital(true);
    }
  }, []);

  return (
    <div className="container">
      <div className="row">
        {isDev ? (
          <div className="sectionTitle">
            <h2>
              We <span className="textGrade titleArrow">Develop</span>
            </h2>
            <p>
              {" "}
              We have honed our skills in developing advanced web platforms,
              mobile applications, and intricate systems that align with the
              latest industry trends.
            </p>
          </div>
        ) : (
          <div className="sectionTitle">
            <h2>
              {props.char1} <span className="textGrade">{props.char2}</span>{" "}
              {props.char3} <span className="titleArrow">{props.char4}</span>
            </h2>
            <p>{props.secPara}</p>
          </div>
        )}

        {isDev ? (
          <div className="syncSliderWrapper">
            <SyncSlider />
          </div>
        ) : (
          <div className="servicesCardWrapper di">
            <div className="servicesCardRow">
              <div className="col-md-3 cutomeWidth">
                <div className="cardInner">
                  <div className="cardicon">
                    <i className="material-symbols-outlined">{props.icon1}</i>
                  </div>
                  <div className="ServiceCardTitle">
                    <h5>{props.cardTitle1}</h5>
                    <p>{props.cardDesc1}</p>
                  </div>
                  {isDigital ? (
                   <div className="getQuoteBtn">
                    <ExpertButton rText="Get Quote" rName="rAnimeBtn expertBtn"/>
                   </div>
                  ) : (
                    <div className="serviceCardLink roundedBtnWrap">
                      <Link
                        type="button"
                        to={props.cardLink1}
                        className="rAnimeBtn"
                      >
                        <span>Learn More</span>
                        <div className="card-arrow arrow-icon-wrapper">
                          <span className="arrow-icon">
                            <img
                              className="blackImg"
                              src={darkBluearrow}
                              alt=""
                            />
                          </span>
                          <span className="arrow-icon arrow-icon-hover">
                            <img
                              className="blackImg"
                              src={darkBluearrow}
                              alt=""
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-3 cutomeWidth">
                <div className="cardInner">
                  <span className="absCircle bgYellow"></span>
                  <div className="cardicon">
                    <i className="material-symbols-outlined">{props.icon2}</i>
                  </div>
                  <div className="ServiceCardTitle">
                    <h5>{props.cardTitle2}</h5>
                    <p>{props.cardDesc2}</p>
                  </div>
                  {isDigital ? (
                    <div className="getQuoteBtn">
                    <ExpertButton rText="Get Quote" rName="rAnimeBtn expertBtn"/>
                   </div>
                  ) : (
                    <div className="serviceCardLink roundedBtnWrap">
                      <Link
                        type="button"
                        to={props.cardLink2}
                        className="rAnimeBtn"
                      >
                        <span>Learn More</span>
                        <div className="card-arrow arrow-icon-wrapper">
                          <span className="arrow-icon">
                            <img
                              className="blackImg"
                              src={darkBluearrow}
                              alt=""
                            />
                          </span>
                          <span className="arrow-icon arrow-icon-hover">
                            <img
                              className="blackImg"
                              src={darkBluearrow}
                              alt=""
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-3 cutomeWidth">
                <div className="cardInner">
                  <span className="absCircle bgLightBlue"></span>
                  <div className="cardicon">
                    <i className="material-symbols-outlined">{props.icon3}</i>
                  </div>
                  <div className="ServiceCardTitle">
                    <h5>{props.cardTitle3}</h5>
                    <p>{props.cardDesc3}</p>
                  </div>
                  {isDigital ? (
                    <div className="getQuoteBtn">
                    <ExpertButton rText="Get Quote" rName="rAnimeBtn expertBtn"/>
                   </div>
                  ) : (
                    <div className="serviceCardLink roundedBtnWrap">
                      <Link
                        type="button"
                        to={props.cardLink4}
                        className="rAnimeBtn"
                      >
                        <span>Learn More</span>
                        <div className="card-arrow arrow-icon-wrapper">
                          <span className="arrow-icon">
                            <img
                              className="blackImg"
                              src={darkBluearrow}
                              alt=""
                            />
                          </span>
                          <span className="arrow-icon arrow-icon-hover">
                            <img
                              className="blackImg"
                              src={darkBluearrow}
                              alt=""
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {showResults ? (
              <div className="servicesCardRow mt-5">
                <div className="col-md-3 cutomeWidth">
                  <div className="cardInner">
                    <div className="cardicon">
                      <i className="material-symbols-outlined">{props.icon4}</i>
                    </div>
                    <div className="ServiceCardTitle">
                      <h5>{props.cardTitle4}</h5>
                      <p>{props.cardDesc4}</p>
                    </div>

                    {isDigital ? (
                      <div className="getQuoteBtn">
                    <ExpertButton rText="Get Quote" rName="rAnimeBtn expertBtn"/>
                   </div>
                    ) : (
                      <div className="serviceCardLink roundedBtnWrap">
                        <Link
                          type="button"
                          to={props.cardLink3}
                          className="rAnimeBtn"
                        >
                          <span>Learn More</span>
                          <div className="card-arrow arrow-icon-wrapper">
                            <span className="arrow-icon">
                              <img
                                className="blackImg"
                                src={darkBluearrow}
                                alt=""
                              />
                            </span>
                            <span className="arrow-icon arrow-icon-hover">
                              <img
                                className="blackImg"
                                src={darkBluearrow}
                                alt=""
                              />
                            </span>
                          </div>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-3 cutomeWidth">
                  <div className="cardInner">
                    <span className="absCircle bgYellow"></span>
                    <div className="cardicon">
                      <i className="material-symbols-outlined">
                        {" "}
                        {props.icon5}
                      </i>
                    </div>
                    <div className="ServiceCardTitle">
                      <h5>{props.cardTitle5}</h5>
                      <p>{props.cardDesc5}</p>
                    </div>
                    {isDigital ? (
                      <div className="getQuoteBtn">
                      <ExpertButton rText="Get Quote" rName="rAnimeBtn expertBtn"/>
                     </div>
                    ) : (
                      <div className="serviceCardLink roundedBtnWrap">
                        <Link
                          type="button"
                          to={props.cardLink5}
                          className="rAnimeBtn"
                        >
                          <span>Learn More</span>
                          <div className="card-arrow arrow-icon-wrapper">
                            <span className="arrow-icon">
                              <img
                                className="blackImg"
                                src={darkBluearrow}
                                alt=""
                              />
                            </span>
                            <span className="arrow-icon arrow-icon-hover">
                              <img
                                className="blackImg"
                                src={darkBluearrow}
                                alt=""
                              />
                            </span>
                          </div>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-3 cutomeWidth">
                  <div className="cardInner">
                    <span className="absCircle bgLightBlue"></span>
                    <div className="cardicon">
                      <i className="material-symbols-outlined">{props.icon6}</i>
                    </div>
                    <div className="ServiceCardTitle">
                      <h5>{props.cardTitle6}</h5>
                      <p>{props.cardDesc6}</p>
                    </div>
                    {isDigital ? (
                      <div className="getQuoteBtn">
                    <ExpertButton rText="Get Quote" rName="rAnimeBtn expertBtn"/>
                   </div>
                    ) : (
                      <div className="serviceCardLink roundedBtnWrap">
                        <Link
                          type="button"
                          to={props.cardLink6}
                          className="rAnimeBtn"
                        >
                          <span>Learn More</span>
                          <div className="card-arrow arrow-icon-wrapper">
                            <span className="arrow-icon">
                              <img
                                className="blackImg"
                                src={darkBluearrow}
                                alt=""
                              />
                            </span>
                            <span className="arrow-icon arrow-icon-hover">
                              <img
                                className="blackImg"
                                src={darkBluearrow}
                                alt=""
                              />
                            </span>
                          </div>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {isIndus ? (
              <div className="servicesCardRow mt-5">
                <div className="col-md-3 cutomeWidth">
                  <div className="cardInner">
                    <div className="cardicon">
                      <i className="material-symbols-outlined">{props.icon4}</i>
                    </div>
                    <div className="ServiceCardTitle">
                      <h5>{props.cardTitle4}</h5>
                      <p>{props.cardDesc4}</p>
                    </div>
                    <div className="serviceCardLink roundedBtnWrap">
                      <Link
                        type="button"
                        to={props.cardLink4}
                        className="rAnimeBtn"
                      >
                        <span>Learn More</span>
                        <div className="card-arrow arrow-icon-wrapper">
                          <span className="arrow-icon">
                            <img
                              className="blackImg"
                              src={darkBluearrow}
                              alt=""
                            />
                          </span>
                          <span className="arrow-icon arrow-icon-hover">
                            <img
                              className="blackImg"
                              src={darkBluearrow}
                              alt=""
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 cutomeWidth">
                  <div className="cardInner">
                    <span className="absCircle bgYellow"></span>
                    <div className="cardicon">
                      <i className="material-symbols-outlined">
                        {" "}
                        {props.icon5}
                      </i>
                    </div>
                    <div className="ServiceCardTitle">
                      <h5>{props.cardTitle5}</h5>
                      <p>{props.cardDesc5}</p>
                    </div>
                    <div className="serviceCardLink roundedBtnWrap">
                      <Link
                        type="button"
                        to={props.cardLink5}
                        className="rAnimeBtn"
                      >
                        <span>Learn More</span>
                        <div className="card-arrow arrow-icon-wrapper">
                          <span className="arrow-icon">
                            <img
                              className="blackImg"
                              src={darkBluearrow}
                              alt=""
                            />
                          </span>
                          <span className="arrow-icon arrow-icon-hover">
                            <img
                              className="blackImg"
                              src={darkBluearrow}
                              alt=""
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 cutomeWidth">
                  <div className="cardInner">
                    <span className="absCircle bgLightBlue"></span>
                    <div className="cardicon">
                      <i className="material-symbols-outlined">{props.icon6}</i>
                    </div>
                    <div className="ServiceCardTitle">
                      <h5>{props.cardTitle6}</h5>
                      <p>{props.cardDesc6}</p>
                    </div>
                    <div className="serviceCardLink roundedBtnWrap">
                      <Link
                        type="button"
                        to={props.cardLink6}
                        className="rAnimeBtn"
                      >
                        <span>Learn More</span>
                        <div className="card-arrow arrow-icon-wrapper">
                          <span className="arrow-icon">
                            <img
                              className="blackImg"
                              src={darkBluearrow}
                              alt=""
                            />
                          </span>
                          <span className="arrow-icon arrow-icon-hover">
                            <img
                              className="blackImg"
                              src={darkBluearrow}
                              alt=""
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {isIndus ? (
              ""
            ) : (
              <div className="viewAllBtnWrap">
                <div className="roundedBtnWrap">
                  <Link
                    type="button"
                    className="rAnimeBtn viewAllBtn"
                    onClick={() => {
                      setShowResults(!showResults);
                    }}
                  >
                    {showResults ? "View Less" : "View All"}
                    <div className="card-arrow arrow-icon-wrapper">
                      <span className="arrow-icon">
                        <img loading="lazy" src={whitearrow} alt="" />
                      </span>
                      <span className="arrow-icon arrow-icon-hover">
                        <img loading="lazy" src={whitearrow} alt="" />
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Industries;
