import React from "react";
import PageBanner from "../../components/TermsPageComponents/PageBanner";
import { pgImg16 } from "../../components";

function SecuredASPDotNET() {
  return (
    <>
      <section id="securityServicesPage" className="securityServicesPage">
        <div className="pagesBanner">
          <PageBanner PageTitle=".NET Development Training" />

          <div className="shapeSvg">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15002 2408">
              <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <path
                  className="fil0"
                  d="M14999.66 160.41l0 2250.5 -15002 0 0 -2408.48c4.48,15.46 34.05,38.31 47.09,51.36 18.3,18.3 33.31,33.31 51.61,51.61l377.15 322.94c339.34,254.69 723.38,451.6 1129.12,576.25 740.24,227.38 1518.28,209.44 2250.74,-34.78 297.55,-99.2 684.02,-289.57 933.07,-476.11 87.62,-65.63 227.27,-165.69 307.34,-240.17 31.42,-29.23 114.35,-99.39 131.22,-124.58 20,4.66 99.59,47.55 124.17,59.83 42.41,21.2 81.55,40.72 122.67,61.33l125.61 58.39c133.82,59.52 249.76,112.63 391.76,169.22 238.34,95 587.91,216.82 832.54,284.94 200.63,55.86 382.28,106.97 590.69,154.3 1557.59,353.7 3775.68,425.94 5382.01,17.95 25.54,-6.48 49.29,-10.39 73.59,-16.17l373.43 -93.31c198.88,-54.19 343.18,-97.87 536.61,-159.02l312.66 -109.2c47.88,-17.88 90.5,-33.57 136.53,-51.96 82.88,-33.12 316.41,-125.5 392.57,-163.92l128.78 -59.7c23.6,-10.77 225.99,-115.13 251.04,-121.22z"
                />
              </g>
            </svg>
          </div>
        </div>
      </section>
      <section
        id="securityServicesContentWrap"
        className="securityServicesContentWrap"
      >
        <div className="container">
          <div className="col-md-12 col-12">
            <div className="contentWrap">
              <div className="row">
                <div className="col-md-7">
                  <p>
                    ASP.NET stands as one of the foremost choices in web
                    development languages, offering developers a versatile
                    programming model to construct web applications. As an
                    integral component of the Microsoft .NET platform, the
                    demand for ASP.NET skills has reached an unprecedented high
                    in the contemporary IT landscape. There is a pressing need
                    within the IT sector for individuals adept at coding web
                    applications using ASP.NET, making it an excellent career
                    choice for those embarking on their professional journey.
                  </p>
                  <p>
                    Version Next Technologies proudly presents the most
                    comprehensive and advanced .NET training in Mumbai. Our team
                    of proficient .NET experts is dedicated to imparting both
                    foundational and advanced knowledge of this programming
                    language. Given that .NET is instrumental in crafting robust
                    web applications and intricate websites, mastering it is a
                    potent skill set. While other .NET training institutes in
                    Mumbai may make promises they can't fulfill, we distinguish
                    ourselves by letting our actions speak for us. Our approach
                    involves hands-on training and real-world projects,
                    employing methodologies that expedite learning, ensuring our
                    students become proficient in .NET within a remarkably short
                    timeframe.
                  </p>
                  <p>
                    The majority of ASP.NET coding entails working with a
                    collection of reusable components and objects within the
                    .NET framework. Becoming a proficient master of this web
                    language necessitates extensive practice and hands-on
                    experience. This is precisely what Version Next Technologies
                    facilitates, guiding you until you possess the capability to
                    develop web applications you can take pride in. We adhere to
                    a set of teaching methods proven to be highly effective for
                    the majority of students. These factors combine to establish
                    Version Next Technologies as the foremost and unparalleled
                    .NET training center in Mumbai.
                  </p>
                </div>
                <div className="col-md-5">
                  <div className="pageImgWrap">
                    <img src={pgImg16} alt=".NET Development Training" />
                  </div>
                </div>
              </div>
            </div>
            <div className="contentWrap">
              <div className="sedtionTitle">
                <h4>Course Outline</h4>
              </div>
              <div className="table-responsive courseTable">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1. Introduction to C#</td>
                    </tr>
                    <tr>
                      <td>2. Introduction to ADO.NET and Basic SQL</td>
                    </tr>
                    <tr>
                      <td>3. Introduction to ASP.NET Web Forms</td>
                    </tr>
                    <tr>
                      <td>4. Introduction to ASP.NET MVC</td>
                    </tr>
                    <tr>
                      <td>5. Introduction To Entity Framework</td>
                    </tr>
                    <tr>
                      <td>6. Introduction to WCF</td>
                    </tr>
                  </tbody>
                </table>
                <b className="boldTxt">
                  Each of the below module contains sub-topics, for complete
                  details contact us.
                </b>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SecuredASPDotNET;
