import React from "react";
import DevelopBannerSection from "../components/Homepage/DevelopPageComponents/DevelopBannerSection";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HealthSliderCard from "../components/HealthcarePageComponent/HealthSliderCard";
import IndusExpert from "../components/IndustriesPageComponents/IndusExpert";
import {
  float1,
  float2,
  keyImg1,
  keyImg2,
  keyImg3,
  keyImg4,
  keyImg5,
  keyImg6,
  mouseSlide10,
  mouseSlide12,
  mouseSlide16,
  mouseSlide9,
  vinayakprofileImg,
} from "../components";
import DevDiff from "../components/Homepage/DevelopPageComponents/DevDiff";
import HealthCareTabSection from "../components/HealthcarePageComponent/HealthCareTabSection";
import HealthcareTabs from "../components/Tabs/HealthcareTabs";
import ExpertButton from "../components/CTA/ExpertButton";
import ScrollingSlide from "../components/MouseScrollingComponent/ScrollingSlide";
function Marketplaces() {
  var settings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: true,
    speed: 800,
    adaptiveHeight: false,
    infinite: true,
    useTransform: true,
    cssEase: "cubic-bezier(0.77, 0, 0.18, 1)",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section id="healthCare" className="healthCare marketPlaceBg">
      <div className="devTopSection">
        <DevelopBannerSection
          line1="Expertly Crafted"
          line2="Custom Marketplace "
          line3="Development Solutions"
          BannerPara="Amidst surging demand, the thriving online marketplace industry seeks modern solutions for sustained competitiveness. Employing advanced technology can elevate user experiences, streamline operations, and ensure continued success in the digital ecosystem."
        />
        <div className="shapeSvg">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15002 2408">
            <g id="Layer_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <path
                className="fil0"
                d="M14999.66 160.41l0 2250.5 -15002 0 0 -2408.48c4.48,15.46 34.05,38.31 47.09,51.36 18.3,18.3 33.31,33.31 51.61,51.61l377.15 322.94c339.34,254.69 723.38,451.6 1129.12,576.25 740.24,227.38 1518.28,209.44 2250.74,-34.78 297.55,-99.2 684.02,-289.57 933.07,-476.11 87.62,-65.63 227.27,-165.69 307.34,-240.17 31.42,-29.23 114.35,-99.39 131.22,-124.58 20,4.66 99.59,47.55 124.17,59.83 42.41,21.2 81.55,40.72 122.67,61.33l125.61 58.39c133.82,59.52 249.76,112.63 391.76,169.22 238.34,95 587.91,216.82 832.54,284.94 200.63,55.86 382.28,106.97 590.69,154.3 1557.59,353.7 3775.68,425.94 5382.01,17.95 25.54,-6.48 49.29,-10.39 73.59,-16.17l373.43 -93.31c198.88,-54.19 343.18,-97.87 536.61,-159.02l312.66 -109.2c47.88,-17.88 90.5,-33.57 136.53,-51.96 82.88,-33.12 316.41,-125.5 392.57,-163.92l128.78 -59.7c23.6,-10.77 225.99,-115.13 251.04,-121.22z"
              />
            </g>
          </svg>
        </div>
      </div>
      <div className="heighlightsSection">
        <div className="container">
          <div className="sectionTitle">
            <h2>Key Takeaways</h2>
            <p>Fifteen Years of Ongoing Transformation</p>
          </div>
          <div className="row">
            <div className="col-md-4 highlightsBox">
              <h5>$262 B</h5>
              <p>
                By 2025, the global marketplace industry is projected to attain
                significant growth.
              </p>
            </div>
            <div className="col-md-4 highlightsBox">
              <h5>$6.51 T</h5>
              <p>
                Global online retail sales are estimated to reach a significant
                milestone by the end of 2023.
              </p>
            </div>
            <div className="col-md-4 highlightsBox">
              <h5>23%</h5>
              <p>Anticipated growth is forecasted to continue until 2025.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="healthServicesSliderWrap retailsServicesSection">
        <div className="container">
          <div className="row sectionFlexHeading mb-5">
            <div className="col-md-8 col-12 mx-auto">
              <div className="sectionTitle text-center">
                <h2>Demand</h2>
                <p className="sectionPara text-center">
                  Examining the Significance of Technology in the Modernization
                  of Marketplace Enterprises.
                </p>
              </div>
            </div>
          </div>
          <div className="healthServicesSlider">
            <Slider {...settings} className="slider-with-gap retailSlider">
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Elevated User Experience"
                  cardPara="Integrating technology enables marketplaces to offer seamless and tailored user experiences, aligning with evolving customer expectations and elevating satisfaction levels."
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Enhanced Operational Efficiency"
                  cardPara="Technology solutions streamline processes, automate tasks, and optimize resource allocation, resulting in enhanced operational efficiency, reduced manual workload, and heightened productivity."
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Competitive Edge"
                  cardPara="Through technology adoption, marketplaces can secure a competitive advantage within the industry, setting them apart from rivals and drawing a larger customer base."
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Scalability"
                  cardPara="Modern technology solutions enable scalability, empowering marketplaces to manage heightened user demand, broaden their offerings, and accommodate growth while maintaining service quality."
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Services Provided by External Parties"
                  cardPara="Technology empowers marketplaces to seamlessly integrate with a variety of third-party services and platforms, including payment gateways, logistics providers, and social media networks."
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Insights Informed by Data"
                  cardPara="Utilizing technology enables marketplaces to gather and analyze data related to customer behavior, preferences, and market trends. This, in turn, empowers them to make informed decisions, refine strategies, and offer tailored solutions."
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <section id="healthCareTabSection">
        <div className="container">
          <HealthCareTabSection
            tabSectionTitle1="Our Solutions for"
            tabSectionTitle2="Marketplace"
            tabSectionTitle3="Software"
            tabSectionPara="Our offerings encompass a wide range of capabilities, tailored to meet the unique needs of various marketplace models, from e-commerce platforms and service marketplaces to peer-to-peer networks. "
          />
          <HealthcareTabs
            tabTitle1="E-commerce Platform Supporting Multiple Vendors"
            tabTitle2="Effortless Payment Processing Solution"
            tabTitle3="Robust Order Management System"
            tabTitle4="Unified Booking and Reservation System"
            tabContentTitle11="E-commerce Platform Supporting Multiple Vendors"
            contentList11="Vendor Management"
            contentList12="Product Listings"
            contentList13="Commission System"
            contentList14="Payment Processing"
            contentList15="Order Management"
            contentList16="Shipping and Logistics"
            contentList17="Search and Filtering"
            tabContentTitle22="Effortless Payment Processing Solution"
            contentList21="Multiple Payment Methods"
            contentList22="Secure Transactions"
            contentList23="Payment Integration"
            contentList24="Mobile Responsiveness"
            contentList25="International Currency Support"
            contentList26="Real-time Authorization"
            tabContentTitle33="Robust Order Management System"
            contentList31="Order Creation and Editing"
            contentList32="Multi-channel Integration"
            contentList33="Inventory Management"
            contentList34="Order Tracking"
            contentList35="Customizable Order Statuses"
            contentList36="Order Splitting and Combining"
            tabContentTitle44="Unified Booking and Reservation System"
            contentList41="Multi-Channel Booking"
            contentList42="Real-Time Availability"
            contentList43="Calendar Synchronization"
            contentList44="Resource Management"
            contentList45="User-friendly Interface"
            contentList46="Custom Booking Rules"
          />
        </div>
      </section>
      <section className="keyAdvantagesSection">
        <div className="container">
          <div className="sectionTitle">
            <h2>Key Areas of Emphasis</h2>
            <p>
              These areas represent the core priorities or main points of
              interest for decision-making and actions.
            </p>
          </div>
          <div className="row gy-5">
            <div className="col-md-4">
              <div className="keyBenifits">
                <div className="keyImgWrap">
                  <img src={keyImg1} alt="Improved Effectiveness" />
                </div>
                <h5>Improved Effectiveness</h5>
                <p>
                  Contemporary IT systems automate processes, enhance resource
                  utilization, and enhance productivity and operational
                  efficiency through streamlined workflows.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="keyBenifits">
                <div className="keyImgWrap">
                  <img src={keyImg2} alt="Decision Making Based on Data" />
                </div>
                <h5>Decision Making Based on Data</h5>
                <p>
                  Information technology (IT) systems analyze customer behavior
                  and market trends to enable informed decision-making, optimize
                  strategies, and identify growth opportunities.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="keyBenifits">
                <div className="keyImgWrap">
                  <img
                    src={keyImg3}
                    alt="Flexibility in Response to Market Shifts"
                  />
                </div>
                <h5>Flexibility in Response to Market Shifts</h5>
                <p>
                  IT systems offer the agility needed for marketplaces to
                  promptly adjust to evolving changes, shifting preferences, and
                  emerging trends.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="keyBenifits">
                <div className="keyImgWrap">
                  <img src={keyImg4} alt="Competitive Edge" />
                </div>
                <h5>Competitive Edge</h5>
                <p>
                  IT systems empower marketplaces by delivering user-friendly,
                  high-speed, and efficient operations, providing a distinct
                  competitive advantage.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="keyBenifits">
                <div className="keyImgWrap">
                  <img src={keyImg5} alt="Improved Security" />
                </div>
                <h5>Improved Security</h5>
                <p>
                  Robust IT systems incorporate advanced security measures to
                  safeguard customer data, secure transactions, and foster trust
                  between buyers and sellers.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="keyBenifits">
                <div className="keyImgWrap">
                  <img src={keyImg6} alt="Improved User Experience" />
                </div>
                <h5>Improved User Experience</h5>
                <p>
                  Contemporary IT systems with intuitive interfaces,
                  personalized recommendations, and streamlined processes result
                  in an enhanced user experience and higher satisfaction levels.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="mouseScrollingSliderSection"
        id="mouseScrollingSliderSection"
      >
        <div className="container">
          <div className="sectionTitle">
            <h2>
              {" "}
              Work <span className="textGrade">Portfolio</span>
            </h2>
            <ExpertButton
              rText="Get Project Quote"
              rName="rAnimeBtn qouteBtn"
            />
          </div>
        </div>
        <ScrollingSlide
          scrollSliderImg1={mouseSlide16}
          scrollSliderImg2={mouseSlide9}
          scrollSliderImg3={mouseSlide10}
          scrollSliderImg4={mouseSlide12}
        />
      </section>
      <section className="IndusExpertSection">
        <IndusExpert
          cName="Version Next Technologies"
          cSecTitle="Why Hire Version Next Technologies as Branding Partner?"
          profileName="Mr. Vinayak Parab"
          designation="Buisness Head"
          cardTitle1="Innovation"
          cardData1="We brings innovative solutions that leverage cutting-edge technologies to drive business growth and stay ahead of the competition."
          cardTitle2="Integrity"
          cardData2="We uphold the highest standards of integrity, ensuring transparency, honesty, and ethical practices in all our interactions with clients."
          cardTitle3="Customer-Obsession"
          cardData3="Our unwavering focus on delivering exceptional customer experiences sets us apart, as we go above and beyond to meet and exceed client expectations."
          cardTitle4="Quality"
          cardData4="We are committed to delivering top-notch quality in all our services, adhering to industry best practices and rigorous quality control processes to ensure excellence."
          profileImg={vinayakprofileImg}
          linkdinLink="http://linkedin.com/in/vinayak-parab-2a7a8615"
          linkdinIcon="fab fa-linkedin"
        />
      </section>
      <section className="whatMakesSection" id="whatMakesSection">
        <DevDiff
          float1={float1}
          float2={float2}
          Char1="What Makes"
          Char2="us"
          Char3="Different?"
          diffDescription="What sets us apart is our expertise, customization, collaboration, creativity, results-driven approach, and customer-centric focus. We have a highly skilled team, personalized solutions, open communication, innovative ideas, a focus on measurable results, and a dedication to customer satisfaction. These factors combine to deliver outstanding outcomes and exceptional experiences for our clients."
        />
      </section>
    </section>
  );
}

export default Marketplaces;
