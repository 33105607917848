import React from "react";
import PageBanner from "../../components/TermsPageComponents/PageBanner";
import { appDevImg } from "../../components";

function AndriodAppDevelopmentTraining() {
  return (
    <>
      <section id="securityServicesPage" className="securityServicesPage">
        <div className="pagesBanner">
          <PageBanner PageTitle="Andriod App Development Training" />

          <div className="shapeSvg">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15002 2408">
              <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <path
                  className="fil0"
                  d="M14999.66 160.41l0 2250.5 -15002 0 0 -2408.48c4.48,15.46 34.05,38.31 47.09,51.36 18.3,18.3 33.31,33.31 51.61,51.61l377.15 322.94c339.34,254.69 723.38,451.6 1129.12,576.25 740.24,227.38 1518.28,209.44 2250.74,-34.78 297.55,-99.2 684.02,-289.57 933.07,-476.11 87.62,-65.63 227.27,-165.69 307.34,-240.17 31.42,-29.23 114.35,-99.39 131.22,-124.58 20,4.66 99.59,47.55 124.17,59.83 42.41,21.2 81.55,40.72 122.67,61.33l125.61 58.39c133.82,59.52 249.76,112.63 391.76,169.22 238.34,95 587.91,216.82 832.54,284.94 200.63,55.86 382.28,106.97 590.69,154.3 1557.59,353.7 3775.68,425.94 5382.01,17.95 25.54,-6.48 49.29,-10.39 73.59,-16.17l373.43 -93.31c198.88,-54.19 343.18,-97.87 536.61,-159.02l312.66 -109.2c47.88,-17.88 90.5,-33.57 136.53,-51.96 82.88,-33.12 316.41,-125.5 392.57,-163.92l128.78 -59.7c23.6,-10.77 225.99,-115.13 251.04,-121.22z"
                />
              </g>
            </svg>
          </div>
        </div>
      </section>
      <section
        id="securityServicesContentWrap"
        className="securityServicesContentWrap"
      >
        <div className="container">
          <div className="col-md-12 col-12">
            <div className="contentWrap">
              <div className="row">
                <div className="col-md-7">
                  <p>
                    Android stands as the world's most widely adopted mobile
                    operating system, renowned for its open-source nature and
                    ownership by Google, fostering a dynamic developer
                    community. In today's landscape, proficiency in Android app
                    development ranks among the most vital skills. However, the
                    absence of comprehensive Android coaching often leaves
                    students struggling to pursue a career in this field.
                  </p>
                  <p>
                    Version Next Technologies, a Mumbai-based security training
                    and service provider, extends its expertise to Android app
                    development courses. If you're seeking a cutting-edge
                    Android training institute in Mumbai, Version Next
                    Technologies is the ultimate destination. Our faculty
                    comprises adept and highly knowledgeable app developers who
                    make the app development journey more accessible than you
                    might imagine. The course is meticulously structured into
                    segments that prioritize hands-on experience, firmly
                    believing that true comprehension of Android app development
                    comes through active application. Consequently, every
                    student is required to undertake a mandatory project to
                    successfully complete the Android app development course.
                  </p>
                  <p>
                    Smartphones are ubiquitous in today's world, with Android
                    commanding over 80% of the market share. The Google Play
                    Store boasts a staggering 2.2 million apps, a number
                    continuously on the rise. This underscores the high demand
                    for Android app developers in the industry. Equipped with
                    app development skills, you can transform your ideas into
                    tangible applications, potentially shaping the next big
                    thing. Furthermore, possessing this expertise paves the way
                    for a successful career with leading global companies.
                  </p>
                </div>
                <div className="col-md-5">
                  <div className="pageImgWrap">
                    <img
                      src={appDevImg}
                      alt="Andriod App Development Training"
                    />
                  </div>
                </div>
              </div>

              <p>
                As the premier Android app development institute in Mumbai, we
                understand the essential steps to refine your skills and provide
                you with the knowledge required to craft exceptional apps.
                Choose Version Next Technologies to propel your career forward
                and remain current in the ever-evolving IT sector, backed by our
                status as a leading authority in the field.
              </p>
            </div>
            <div className="contentWrap">
              <div className="sedtionTitle">
                <h4>Course Outline</h4>
              </div>
              <div className="table-responsive courseTable">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1. Basics of C/C+</td>
                    </tr>
                    <tr>
                      <td>2. Core Java</td>
                    </tr>
                    <tr>
                      <td>3. Introduction to Android</td>
                    </tr>
                    <tr>
                      <td>4. Android Architecture Overview</td>
                    </tr>
                    <tr>
                      <td>5. Set-up of Android Development Environment</td>
                    </tr>
                    <tr>
                      <td>6. Creating Applications and Activities</td>
                    </tr>
                    <tr>
                      <td>7. Creating User Interfaces</td>
                    </tr>
                    <tr>
                      <td>8. Activities, Intents & Intent Filters</td>
                    </tr>
                    <tr>
                      <td>9. Database and Content Providers</td>
                    </tr>
                    <tr>
                      <td>10. Android Application Deployment</td>
                    </tr>
                    <tr>
                      <td>11. Basic Cyber Defense</td>
                    </tr>
                  </tbody>
                </table>
                <b className="boldTxt">
                  Each of the below module contains sub-topics, for complete
                  details contact us.
                </b>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AndriodAppDevelopmentTraining;
