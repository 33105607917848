import React from "react";
import BannerLeftBox from "./components/BannerLeftBox";
import { Accodion } from "../../components/Accordion/AccordionItem";
import TransparentRoundedBtn from "../../components/CTA/TransparentRoundedBtn";
import HireModel from "./components/HireModel";
import FaqVideo from "../../components/FaqVideo/FaqVideo";
import WhyHire from "./components/WhyHire";
import YourNeedSection from "./components/YourNeedSection";
import HireExpertise from "./components/HireExpertise";
import { YourNeed } from "./components/AspDotNetPage/ArrayData/YourNeed";
import { ExpertiseData } from "./components/AspDotNetPage/ArrayData/ExpertiseData";
function AspDotNetDeveloperHire() {
  return (
    <section id="hireDevPage" className="hireDevPage">
      <div className="hireBannerSection aspBg">
        <BannerLeftBox line1="Hire a VNT" line2="ASP.Net" line3="Developer" />
        <div className="shapeSvg">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15002 2408">
            <g id="Layer_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <path
                className="fil0"
                d="M14999.66 160.41l0 2250.5 -15002 0 0 -2408.48c4.48,15.46 34.05,38.31 47.09,51.36 18.3,18.3 33.31,33.31 51.61,51.61l377.15 322.94c339.34,254.69 723.38,451.6 1129.12,576.25 740.24,227.38 1518.28,209.44 2250.74,-34.78 297.55,-99.2 684.02,-289.57 933.07,-476.11 87.62,-65.63 227.27,-165.69 307.34,-240.17 31.42,-29.23 114.35,-99.39 131.22,-124.58 20,4.66 99.59,47.55 124.17,59.83 42.41,21.2 81.55,40.72 122.67,61.33l125.61 58.39c133.82,59.52 249.76,112.63 391.76,169.22 238.34,95 587.91,216.82 832.54,284.94 200.63,55.86 382.28,106.97 590.69,154.3 1557.59,353.7 3775.68,425.94 5382.01,17.95 25.54,-6.48 49.29,-10.39 73.59,-16.17l373.43 -93.31c198.88,-54.19 343.18,-97.87 536.61,-159.02l312.66 -109.2c47.88,-17.88 90.5,-33.57 136.53,-51.96 82.88,-33.12 316.41,-125.5 392.57,-163.92l128.78 -59.7c23.6,-10.77 225.99,-115.13 251.04,-121.22z"
              />
            </g>
          </svg>
        </div>
      </div>
      <section id="hireModelSection">
        <HireModel techName="ASP.Net" />
      </section>
      <section id="whyHireSection">
        <div className="rightBtm"></div>
        <WhyHire
          techName="ASP.Net"
          techPara=" Version Next Technologies is proud to be different from other
        similar companies. With best price and understanding toward market
        trend both small and large companies are taking advantage of such
        apps."
        />
      </section>
      <section id="yourNeedSection">
        <YourNeedSection techName="ASP.Net" yourNeedData={YourNeed}/>
      </section>
      <section id="hireExpertiseSection">
        <HireExpertise expertiseData={ExpertiseData}/>
      </section>
      <section className="commonFaq">
        <div className="container">
          <div className="sectionTitle">
            <h2 className="mb-5">
              Frequently Asked <span className="textGrade">Questions</span>
            </h2>

          </div>
          <div className="row">
            <div className="col-md-12 col-lg-6 col-12">
              <Accodion />
            </div>
            <div className="col-md-12 col-lg-6 col-12">
              <FaqVideo />
            </div>
          </div>
          <div className="readMoreBtnWrap">
            <TransparentRoundedBtn
              rText="Read More"
              rLink="/faqs"
              rName="rAnimeBtn readMoretBtn"
            />
          </div>
        </div>
      </section>
    </section>
  );
}

export default AspDotNetDeveloperHire;
