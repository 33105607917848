import React from "react";
import DevelopBannerSection from "../components/Homepage/DevelopPageComponents/DevelopBannerSection";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HealthSliderCard from "../components/HealthcarePageComponent/HealthSliderCard";
import IndusExpert from "../components/IndustriesPageComponents/IndusExpert";
import { float1, float2, sanyogprofileImg } from "../components";
import DevDiff from "../components/Homepage/DevelopPageComponents/DevDiff";
import CaseStudyNew from "../components/Homepage/CaseStudy/CaseStudyNew";
import HealthCareTabSection from "../components/HealthcarePageComponent/HealthCareTabSection";
import HealthcareTabs from "../components/Tabs/HealthcareTabs";
function Sports() {
  var settings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: true,
    speed: 800,
    adaptiveHeight: false,
    infinite: true,
    useTransform: true,
    cssEase: "cubic-bezier(0.77, 0, 0.18, 1)",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section id="healthCare" className="healthCare sportsBg">
      <div className="devTopSection">
        <DevelopBannerSection
          line1="Innovations by"
          line2="Sports & Gaming"
          line3="Development Company"
          BannerPara="Dive into the world of sports and gaming innovation with our cutting-edge development company. Harness our expertise in game development to create top-tier sports solutions meticulously crafted for monitoring and in-depth sports analysis. "
        />
        <div className="shapeSvg">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15002 2408">
            <g id="Layer_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <path
                className="fil0"
                d="M14999.66 160.41l0 2250.5 -15002 0 0 -2408.48c4.48,15.46 34.05,38.31 47.09,51.36 18.3,18.3 33.31,33.31 51.61,51.61l377.15 322.94c339.34,254.69 723.38,451.6 1129.12,576.25 740.24,227.38 1518.28,209.44 2250.74,-34.78 297.55,-99.2 684.02,-289.57 933.07,-476.11 87.62,-65.63 227.27,-165.69 307.34,-240.17 31.42,-29.23 114.35,-99.39 131.22,-124.58 20,4.66 99.59,47.55 124.17,59.83 42.41,21.2 81.55,40.72 122.67,61.33l125.61 58.39c133.82,59.52 249.76,112.63 391.76,169.22 238.34,95 587.91,216.82 832.54,284.94 200.63,55.86 382.28,106.97 590.69,154.3 1557.59,353.7 3775.68,425.94 5382.01,17.95 25.54,-6.48 49.29,-10.39 73.59,-16.17l373.43 -93.31c198.88,-54.19 343.18,-97.87 536.61,-159.02l312.66 -109.2c47.88,-17.88 90.5,-33.57 136.53,-51.96 82.88,-33.12 316.41,-125.5 392.57,-163.92l128.78 -59.7c23.6,-10.77 225.99,-115.13 251.04,-121.22z"
              />
            </g>
          </svg>
        </div>
      </div>
      <div className="healthServicesSliderWrap">
        <div className="container">
          <div className="row sectionFlexHeading">
            <div className="col-md-6 col-12">
              <div className="sectionTitle">
                <h2>
                  Leading Fantasy Sports Software Development Among Top Gaming
                  App Companies
                </h2>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <p className="sectionPara">
                Explore Version Next Technologies, a frontrunner among gaming
                app development companies, dedicated to crafting innovative
                solutions for fantasy sports software development.
              </p>
            </div>
          </div>
          <div className="healthServicesSlider">
            <Slider {...settings} className="slider-with-gap">
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Sports News and Info App"
                  cardPara="Create a comprehensive news app tailored for passionate sports enthusiasts. Covering major sports such as soccer, tennis, cricket, rugby, and more, this app offers seamless access to the latest updates from the world of sports, ensuring hassle-free information for devoted fans."
                  cardLink=""
                  btnName="Get consultation"
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Seamless Live Streaming App"
                  cardPara="Elevate your customers' live match experience through a dedicated live streaming app crafted by our skilled sports app developers. This effortless platform guarantees an exceptional user journey, delivering real-time updates."
                  cardLink=""
                  btnName="Leave a request"
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Fantasy Sports App"
                  cardPara="Develop an app that empowers users to curate their virtual dream teams for global exotic sports events. Our fantasy sports app provides an unmatched chance for sports enthusiasts worldwide to unite, collaborate, and engage in their favorite games."
                  cardLink=""
                  btnName="Order an estimate"
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <section id="healthCareTabSection">
        <div className="container">
          <HealthCareTabSection
            tabSectionTitle1=""
            tabSectionTitle2="Sports & Gaming Development "
            tabSectionTitle3="At Your Fingertips"
            tabSectionPara=" Immerse yourself in the world of Sports & Gaming Development with our expert solutions tailored to your needs. Whether it's crafting engaging games or innovative sports applications, we're here to bring your visions to life."
          />
          <HealthcareTabs
            tabTitle1="Ticket Booking App"
            tabTitle2="Team Management Solution"
            tabTitle3="Coaching App"
            tabTitle4="Sponsorship management software"
            tabContentTitle11="Ticket Booking App"
            contentList11="Global Event Access"
            contentList12="Real-Time Booking"
            contentList13="Event Details"
            contentList14="Secure Payments"
            contentList15="Seat Selection"
            contentList16="Booking History"
            tabContentTitle22="Team Management Solution"
            contentList21="Task Assignment"
            contentList22="Task Tracking"
            contentList23="Progress Analysis"
            contentList24="Performance Optimization"
            contentList25="Collaborative Workspace"
            contentList26="Task Prioritization"
            tabContentTitle33="Coaching App"
            contentList31="Online Coaching Platform"
            contentList32="Flexible Service Offerings"
            contentList33="Scheduling and Calendar"
            contentList34="Secure Payment Processing"
            contentList35="Client Management"
            contentList36="Booking and Rescheduling"
            tabContentTitle44="Sponsorship management software"
            contentList41="Contact and Relationship Management"
            contentList42="Sponsorship Proposal and Agreement"
            contentList43="Customization and Branding"
            contentList44="Benefits and Packages Management"
            contentList45="Event Management"
            contentList46="Activity and Task Management"
          />
        </div>
      </section>
      <section id="case_study" className="case_study">
        <CaseStudyNew
          titleChar1="We Provide"
          titleChar2="Software Solutions"
          projectTitle10="1. Utsav Jewels"
          projectPara11="Utssav Jwelles had a strong presence in the offline market but wanted to expand their reach to a wider audience by selling their products online. They approached us at Version Next to help them needed a user-friendly e-commerce website that provided a seamless shopping experience to their customers."
          projectPara12="To address Utssav Jwelles' requirements, we designed and developed an e-commerce with mobile app for Utssav Jwelles to manage their orders and deliveries. The app allowed them to view all orders and track their status in real-time. The application was built using a modern stack of technologies to ensure fast loading times and smooth checkout processes."
          projectPara13="At Version Next, we take pride in delivering high-quality solutions that are tailored to our client's business needs. Our collaboration with Utssav Jwelles is an example of how we leverage our expertise in web and mobile app development to help businesses achieve their goals."
          projectTitle20="2. Building an ERP Tool for Heritage"
          projectPara21="Heritage, a leading company in the industry, sought to enhance their customer experience by developing an ERP tool. This tool aimed to seamlessly connect customers with the ability to order subscription-based products through their app. Additionally, it would establish a connection to the nearest Heritage branch responsible for fulfilling the orders and facilitating timely deliveries. Version Next, an experienced IT services provider, was enlisted to build this innovative solution."
          projectPara22="Version Next's development team meticulously crafted the ERP tool, leveraging industry-standard technologies to ensure a robust and reliable solution. The user interface was designed with a customer-centric approach, focusing on simplicity and ease of use while offering a comprehensive ordering experience. The ERP tool transformed Heritage's customer experience by providing a convenient, user-friendly platform for ordering subscription-based products."
          projectPara23="The solution provided Heritage with a competitive edge by enhancing customer satisfaction, optimizing internal operations, and supporting future growth."
          totlaCount="2"
        />
      </section>
      <section className="IndusExpertSection">
        <IndusExpert
          cName="Version Next Technologies"
          cSecTitle="At VNT, we stand as the foremost choice for crafting enterprise game development software. Here's why:"
          profileName="Mr. Sanyogg Shelar"
          designation="Buisness Head"
          cardTitle1="Innovative Solutions"
          cardData1="Our approach is rooted in innovation, ensuring your enterprise game software is at the forefront of technology."
          cardTitle2="Unparalleled Expertise"
          cardData2="With our seasoned team, we bring unmatched experience and skill to every aspect of game development."
          cardTitle3="Seamless Integration"
          cardData3="Our software seamlessly integrates with your existing systems, streamlining your enterprise operations."
          cardTitle4="Quality Assurance "
          cardData4="Our commitment to excellence guarantees a final product that meets the highest standards of quality."
          profileImg={sanyogprofileImg}
          facebookLink="https://profiles.wordpress.org/codexdemon/"
          facebookIcon="fab fa-wordpress"
          twiiterLink="https://twitter.com/sanyog"
          twitterIcon="fab fa-twitter-square"
          linkdinLink="https://www.linkedin.com/in/sanyogs"
          linkdinIcon="fab fa-linkedin"
        />
      </section>
      <section className="whatMakesSection" id="whatMakesSection">
        <DevDiff
          float1={float1}
          float2={float2}
          Char1="What Makes"
          Char2="us"
          Char3="Different?"
          diffDescription="What sets us apart is our expertise, customization, collaboration, creativity, results-driven approach, and customer-centric focus. We have a highly skilled team, personalized solutions, open communication, innovative ideas, a focus on measurable results, and a dedication to customer satisfaction. These factors combine to deliver outstanding outcomes and exceptional experiences for our clients."
        />
      </section>
    </section>
  );
}

export default Sports;
