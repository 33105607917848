import React from "react";
import PageBanner from "../../components/TermsPageComponents/PageBanner";
import { pgImg7, pgImg8 } from "../../components";

function SpearPhishing() {
  return (
    <>
      <section id="securityServicesPage" className="securityServicesPage">
        <div className="pagesBanner">
          <PageBanner PageTitle="Spear Phishing" />

          <div className="shapeSvg">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15002 2408">
              <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <path
                  className="fil0"
                  d="M14999.66 160.41l0 2250.5 -15002 0 0 -2408.48c4.48,15.46 34.05,38.31 47.09,51.36 18.3,18.3 33.31,33.31 51.61,51.61l377.15 322.94c339.34,254.69 723.38,451.6 1129.12,576.25 740.24,227.38 1518.28,209.44 2250.74,-34.78 297.55,-99.2 684.02,-289.57 933.07,-476.11 87.62,-65.63 227.27,-165.69 307.34,-240.17 31.42,-29.23 114.35,-99.39 131.22,-124.58 20,4.66 99.59,47.55 124.17,59.83 42.41,21.2 81.55,40.72 122.67,61.33l125.61 58.39c133.82,59.52 249.76,112.63 391.76,169.22 238.34,95 587.91,216.82 832.54,284.94 200.63,55.86 382.28,106.97 590.69,154.3 1557.59,353.7 3775.68,425.94 5382.01,17.95 25.54,-6.48 49.29,-10.39 73.59,-16.17l373.43 -93.31c198.88,-54.19 343.18,-97.87 536.61,-159.02l312.66 -109.2c47.88,-17.88 90.5,-33.57 136.53,-51.96 82.88,-33.12 316.41,-125.5 392.57,-163.92l128.78 -59.7c23.6,-10.77 225.99,-115.13 251.04,-121.22z"
                />
              </g>
            </svg>
          </div>
        </div>
      </section>
      <section
        id="securityServicesContentWrap"
        className="securityServicesContentWrap"
      >
        <div className="container">
          <div className="col-md-12 col-12">
            <div className="contentWrap">
              <p>
                The internet is susceptible to numerous deceptive tactics
                designed to trick users into clicking on harmful links or
                downloading malicious files, potentially damaging their systems.
                This issue is pervasive and impacts a vast majority of internet
                users. Shockingly, it has been reported that an overwhelming 91%
                of hacking attempts commence with phishing, as it proves quite
                effective at deceiving ordinary users.
              </p>
              <p>
                Recently, spear phishing email campaigns have grown more
                targeted and are achieving higher conversion rates. Common users
                often struggle to distinguish between genuine emails or messages
                and fraudulent ones. This is the principal reason behind the
                rising success of spear phishing attacks.
              </p>
            </div>
            <div className="contentWrap">
              <div className="row">
                <div className="col-md-7">
                  <div className="sedtionTitle">
                    <h4>
                      What sets Version Next Technologies apart for Spear
                      Phishing services?
                    </h4>
                  </div>
                  <p>
                    If you're a business owner, it's crucial to initiate the
                    education of your employees on recognizing spear phishing
                    messages. Most hacker groups tend to follow a specific
                    pattern, and once you become familiar with it, the ability
                    to prevent such campaigns from harming your company
                    significantly improves.
                  </p>
                  <p>
                    Version Next Technologies is fully equipped with the
                    appropriate technologies and skilled professionals to offer
                    you solutions that effectively lead to spear phishing
                    prevention. We excel in providing the most robust spear
                    phishing protection measures, allowing you to secure your
                    business against these malicious attacks.
                  </p>
                </div>
                <div className="col-md-5">
                  <div className="pageImgWrap">
                    <img src={pgImg7} alt="Spear Phishing services" />
                  </div>
                </div>
              </div>
            </div>
            <div className="contentWrap">
              <div className="row">
                <div className="col-md-5">
                  <div className="pageImgWrap">
                    <img src={pgImg8} alt="Spear Phishing services" />
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="sedtionTitle">
                    <h4>What distinguishes Phishing from Spear Phishing?</h4>
                  </div>
                  <p>
                    The key distinction between phishing and spear phishing
                    attacks is that the former typically pretends to be from a
                    reputable company, while the latter poses as an email from
                    an individual in a position of authority. Nevertheless, both
                    types of attacks can have similar consequences for your
                    company or employees. The techniques employed by Version
                    Next Technologies to address these threats will also bear
                    similarities, as they both fall within a specific category
                    of hacking attempts.{" "}
                  </p>
                  <p>
                    Version Next Technologies, a premier security agency in
                    Mumbai, provides exceptional services tailored to a variety
                    of needs. We offer top-tier information security awareness
                    training for your employees or professionals. These lessons
                    are best-in-class and are designed to help prevent various
                    types of spear phishing attacks.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SpearPhishing;
