import React from "react";
import { service1, service2, service3, service4, service5, service6, service7 } from "..";

function ServiceOfferSection() {
  return (
    <div className="container">
      <div className="sectionTitle">
        <h2>
          Web Development <span className="textGrade">Services We Offer</span>
        </h2>
        <p>
          At Version Next Technologies, we offer a comprehensive range of
          services. Our approach involves delving deep into our clients'
          objectives and understanding their unique business challenges in order
          to provide tailored web app solutions that best meet their needs.
        </p>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftWrap">
            <h3>Custom application development</h3>
            <p>
              As a dedicated web app development company, we are capable of
              efficiently and cost-effectively fulfilling a variety of needs.
              Whether you require the development of a custom web app from the
              ground up, migration of your legacy backend systems, or
              optimization of existing front-end functionality, we have you
              covered.
            </p>
            <p>
              Additionally, we provide a range of specialized services,
              including business intelligence solutions, cloud migration for
              applications, collaboration and portal development, document
              management solutions, establishment of secure intranets,
              extranets, and portals, as well as ongoing maintenance, support,
              and upgrades.
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightWrap">
            <img
              src={service1}
              alt="Custom application development"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftWrap">
            <h3>Portal development</h3>
            <p>
              Being one of the highly sought-after web app development
              companies, we excel in creating web portals and enterprise portals
              that perfectly align with your specific business needs. Our web
              portals are designed to deliver exceptional performance,
              cost-efficiency, and growth potential.
            </p>
            <p>
              For enterprise-level deployments, we integrate productive
              intranets, extranets, and engaging workspaces. Our portal
              solutions encompass extensive collaborative features, access to
              consolidated information, self-service workflows, and enterprise
              social functionality.
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightWrap">
            <img
              src={service2}
              alt="Portal development"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftWrap">
            <h3>E-Commerce development</h3>
            <p>
              As a website app development company, we specialize in providing
              comprehensive eCommerce solutions. Our services encompass
              end-to-end support, including payment gateway integration,
              integration of high-end plug-in modules, round-the-clock
              maintenance, and creating appealing user experiences (UX) that
              result in delightful interactions for end users.
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightWrap">
            <img
              src={service3}
              alt="E-Commerce development"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftWrap">
            <h3>Website development and maintenance</h3>
            <p>
              As a dedicated website application development company, we
              specialize in creating websites that prioritize ease of
              navigation, robust information architecture, visual cues for
              users, and action-oriented design.
            </p>
            <p>
              Our comprehensive services cover domain name registration, content
              development and management systems, navigation design,
              programming, user interface design, and more. Rest assured, our
              websites are designed to function seamlessly across various screen
              sizes and resolutions, providing an optimal user experience.
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightWrap">
            <img
              src={service4}
              alt="Website development and maintenance"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftWrap">
            <h3>SaaS products</h3>
            <p>
              Open source development is a concept that enables you to develop
              or revamp your website using readily available source code that is
              openly accessible. The key distinction is that the source codes
              are visible on the website's web page.
            </p>
            <p>
              With our open source website revamp and development services, you
              have the flexibility to modify the source code of your website,
              allowing for quicker implementation and cost-effective solutions
              that might otherwise be unachievable within tight timelines and
              limited budgets.
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightWrap">
            <img
              src={service5}
              alt="SaaS products"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftWrap">
            <h3>Legacy software modernization</h3>
            <p>
              Our application modernization services are designed to lower
              operational costs associated with legacy applications, while
              simultaneously enhancing their features, performance, and
              security. We ensure that the modernized solutions remain scalable
              and easy to maintain over the long term.
            </p>
            <p>
              Following mature software modernization and risk management
              practices, Version Next Technologies provides comprehensive
              end-to-end app modernization services. Our goal is to assist
              businesses in extracting new value from their legacy solutions and
              driving innovation.
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightWrap">
            <img
              src={service6}
              alt="Legacy software modernization"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftWrap">
            <h3>Web application support and maintenance</h3>
            <p>
              We collaborate closely with you to provide comprehensive web
              application maintenance services, regardless of whether the
              applications were developed by us or by third-party vendors. Our
              experienced engineers excel in fixing bugs and seamlessly adding
              new functionalities to enhance your applications. Moreover, we
              diligently document all the changes and provide ongoing support to
              address all your future needs with efficiency and expertise.
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightWrap">
            <img
              src={service7}
              alt="Web application support and maintenance"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceOfferSection;
