import React, { useState } from "react";

import { Link } from "react-router-dom";

function TechStack() {
  const [isActive, setIsActive] = useState(1);
  const handleActive = (btn) => setIsActive(btn);
  return (
    <>
      <div className="container">
        <div className="sectionTitle">
          <h2>
            <span className="textGrade">Technology</span> Stack
          </h2>
          <p>
            Our development team exclusively leverages modern and scalable
            technologies to precisely execute mobile and web applications
            according to your vision.
          </p>
        </div>
      </div>
      <div className="container techTabsWrap">
        <div className="techTabBtnWrap">
          <button
            className={`tabs ${isActive === 1 ? "activeTab" : ""}`}
            onClick={() => handleActive(1)}
          >
            Mobile
          </button>
          <button
            className={`tabs ${isActive === 2 ? "activeTab" : ""}`}
            onClick={() => handleActive(2)}
          >
            Front End
          </button>
          <button
            className={`tabs ${isActive === 3 ? "activeTab" : ""}`}
            onClick={() => handleActive(3)}
          >
            Database
          </button>
          <button
            className={`tabs ${isActive === 4 ? "activeTab" : ""}`}
            onClick={() => handleActive(4)}
          >
            Backend
          </button>
          <button
            className={`tabs ${isActive === 5 ? "activeTab" : ""}`}
            onClick={() => handleActive(5)}
          >
            CMS
          </button>
          <button
            className={`tabs ${isActive === 6 ? "activeTab" : ""}`}
            onClick={() => handleActive(6)}
          >
            Infra and DevOps
          </button>
        </div>
        
          <div className="techRow">
            <div className="col-lg-10 col-md-12 col-12 mx-auto">
              <div className="techTabContentWrap">
                {isActive === 1 && (
                  <div className="techTabContentBox">
                    <div className="techLogoRow">
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box iOSBg">
                            
                          </div>
                          <h3>iOS</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box AndroidBg">
                            
                          </div>
                          <h3>Android</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box NativeBg">
                            
                          </div>
                          <h3>React Native</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box FlutterBg">
                            
                          </div>
                          <h3>Flutter</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box IonicBg">
                            
                          </div>
                          <h3>Ionic</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box SwiftBg">
                            
                          </div>
                          <h3>Swift</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box KotlinBg">
                            
                          </div>
                          <h3>Kotlin</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box ObjectiveCBg">
                            
                          </div>
                          <h3>ObjectiveC</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box TitaniumBg">
                            
                          </div>
                          <h3>Titanium</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
                {isActive === 2 && (
                  <div className="techTabContentBox">
                    <div className="techLogoRow">
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box AngularBg">
                            
                          </div>
                          <h3>Angular Js</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box ReactBg">
                            
                          </div>
                          <h3>React Js</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box TypeScriptBg">
                            
                          </div>
                          <h3>TypeScript</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box VueBg">
                            
                          </div>
                          <h3>Vue</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box HTMLBg">
                            
                          </div>
                          <h3>HTML5</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
                {isActive === 3 && (
                  <div className="techTabContentBox">
                    <div className="techLogoRow">
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box MongoBg">
                            
                          </div>
                          <h3>Mongo DB</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box MySQLBg">
                            
                          </div>
                          <h3>MySQL</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box FirebaseBg">
                            
                          </div>
                          <h3>Firebase</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box RealmBg">
                            
                          </div>
                          <h3>Realm</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box DynamoDBBg">
                            
                          </div>
                          <h3>DynamoDB</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box OracleBg">
                            
                          </div>
                          <h3>Oracle</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box PostgreSQLBg">
                            
                          </div>
                          <h3>PostgreSQL</h3> 
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box RedisBg">
                            
                          </div>
                          <h3>Redis</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
                {isActive === 4 && (
                  <div className="techTabContentBox">
                    <div className="techLogoRow">
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box PHPBg">
                            
                          </div>
                          <h3>PHP</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box JavaBg">
                            
                          </div>
                          <h3>Java</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box NETBg">
                            
                          </div>
                          <h3>.NET</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box NodeBg">
                            
                          </div>
                          <h3>Node JS</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box RailsBg">
                            
                          </div>
                          <h3>Rails</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box PythonBg">
                            
                          </div>
                          <h3>Python</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
                {isActive === 5 && (
                  <div className="techTabContentBox">
                    <div className="techLogoRow">
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box DrupalBg">
                            
                          </div>
                          <h3>Drupal</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box JoomlaBg">
                            
                          </div>
                          <h3>Joomla</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box WordpressBg">
                            
                          </div>
                          <h3>Wordpress</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box MagentoBg">
                            
                          </div>
                          <h3>Magento</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box ShopifyBg">
                            
                          </div>
                          <h3>Shopify</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
                {isActive === 6 && (
                  <div className="techTabContentBox">
                    <div className="techLogoRow">
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box AWSBg">
                            
                          </div>
                          <h3>AWS</h3> 
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box CloudBg">
                            
                          </div>
                          <h3>Google Cloud</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box GradleBg">
                            
                          </div>
                          <h3>Gradle</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box JenkinsBg">
                            
                          </div>
                          <h3>Jenkins</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box AppiumBg">
                            
                          </div>
                          <h3>Appium</h3>
                        </Link>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <Link className="icon-block" to="">
                          <div className="icon-box SeleniumBg">
                            
                          </div>
                          <h3>Selenium</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        
      </div>
    </>
  );
}

export default TechStack;
