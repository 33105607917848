import React from "react";
import DevelopBannerSection from "../components/Homepage/DevelopPageComponents/DevelopBannerSection";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HealthSliderCard from "../components/HealthcarePageComponent/HealthSliderCard";
import IndusExpert from "../components/IndustriesPageComponents/IndusExpert";
import { float1, float2, mouseSlide1, mouseSlide2, mouseSlide3, mouseSlide4, sanyogprofileImg} from "../components";
import DevDiff from "../components/Homepage/DevelopPageComponents/DevDiff";
import HealthCareTabSection from "../components/HealthcarePageComponent/HealthCareTabSection";
import HealthcareTabs from "../components/Tabs/HealthcareTabs";
import ScrollingSlide from "../components/MouseScrollingComponent/ScrollingSlide";
import ExpertButton from "../components/CTA/ExpertButton";
function Logistics() {
  var settings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: true,
    speed: 800,
    adaptiveHeight: false,
    infinite: true,
    useTransform: true,
    cssEase: "cubic-bezier(0.77, 0, 0.18, 1)",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section id="healthCare" className="healthCare logisticBg">
      <div className="devTopSection">
        <DevelopBannerSection
          line1="Navigating Tomorrow's"
          line2="Logistics With Today's"
          line3="Software Solutions"
          BannerPara="Version Next Technologies takes pride in creating user-friendly interfaces that minimize the learning curve and maximize productivity. With a focus on intuitive design, our software solutions enable your team to adapt quickly and make the most of the features at hand."
        />
        <div className="shapeSvg">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15002 2408">
            <g id="Layer_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <path
                className="fil0"
                d="M14999.66 160.41l0 2250.5 -15002 0 0 -2408.48c4.48,15.46 34.05,38.31 47.09,51.36 18.3,18.3 33.31,33.31 51.61,51.61l377.15 322.94c339.34,254.69 723.38,451.6 1129.12,576.25 740.24,227.38 1518.28,209.44 2250.74,-34.78 297.55,-99.2 684.02,-289.57 933.07,-476.11 87.62,-65.63 227.27,-165.69 307.34,-240.17 31.42,-29.23 114.35,-99.39 131.22,-124.58 20,4.66 99.59,47.55 124.17,59.83 42.41,21.2 81.55,40.72 122.67,61.33l125.61 58.39c133.82,59.52 249.76,112.63 391.76,169.22 238.34,95 587.91,216.82 832.54,284.94 200.63,55.86 382.28,106.97 590.69,154.3 1557.59,353.7 3775.68,425.94 5382.01,17.95 25.54,-6.48 49.29,-10.39 73.59,-16.17l373.43 -93.31c198.88,-54.19 343.18,-97.87 536.61,-159.02l312.66 -109.2c47.88,-17.88 90.5,-33.57 136.53,-51.96 82.88,-33.12 316.41,-125.5 392.57,-163.92l128.78 -59.7c23.6,-10.77 225.99,-115.13 251.04,-121.22z"
              />
            </g>
          </svg>
        </div>
      </div>
      <div className="healthServicesSliderWrap">
        <div className="container">
          <div className="row sectionFlexHeading">
            <div className="col-md-6 col-12">
              <div className="sectionTitle">
                <h2>The Solutions for Developing Logistics Software That We Provide</h2>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <p className="sectionPara">
                Welcome to Version Next Technologies Solutions, your gateway to
                revolutionary logistics software solutions. In a world where
                efficient logistics can make or break a business, we offer you a
                suite of cutting-edge tools to transform your operations into a
                streamlined and competitive force.
              </p>
            </div>
          </div>
          <div className="healthServicesSlider">
            <Slider {...settings} className="slider-with-gap">
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Logistics and Transportation Management"
                  cardPara="From streamlined apps to comprehensive platforms, we create tailored logistics software development solutions. Our highly scalable logistics solutions automate complex tasks, while our adept developers craft transportation systems to precisely match your project requirements."
                  cardLink=""
                  btnName="Get consultation"
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Fleet Management Software"
                  cardPara="Elevate your fleet management solutions with the expertise of VNT, a premier company in logistics software development. Our forward-looking technology stack empowers you to effortlessly track, monitor, and proficiently oversee your vehicle operations."
                  cardLink=""
                  btnName="Leave a request"
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Warehouse Management Solutions"
                  cardPara="Forge innovative warehouse management solutions fueled by the latest technology stack iterations. This implementation empowers companies to optimize inventory management with precision."
                  cardLink=""
                  btnName="Order an estimate"
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <section id="healthCareTabSection">
        <div className="container">
          <HealthCareTabSection
            tabSectionTitle1="Logistics"
            tabSectionTitle2="Software Solutions"
            tabSectionTitle3="At Your Service"
            tabSectionPara="Our logistics software solutions are meticulously crafted to cater to the intricate demands of the industry. From optimizing supply chains to automating warehouse management, our products are designed with one goal in mind: to enhance efficiency and drive growth."
          />
          <HealthcareTabs
            tabTitle1="Supply Chain Management Software"
            tabTitle2="Traffic Management Software"
            tabTitle3="Auction and Smart Contracts"
            tabTitle4="Freight Management Software"
            tabContentTitle11="Supply Chain Management Software"
            contentList11="Inventory Management"
            contentList12="Demand Forecasting"
            contentList13="Order Management"
            contentList14="Supplier Relationship Management"
            contentList15="Warehouse Management"
            contentList16="Logistics and Transportation Management"
            tabContentTitle22="Traffic Management Software"
            contentList21="Traffic Monitoring and Analysis"
            contentList22="Traffic Flow Optimization"
            contentList23="Intelligent Traffic Signals"
            contentList24="Route Planning and Optimization"
            contentList25="Public Transportation Integration"
            contentList26="Parking Management"
            tabContentTitle33="Auction and Smart Contracts"
            contentList31="User Registration and Profiles"
            contentList32="Listing Management"
            contentList33="Bidding Options"
            contentList34="Real-time Updates"
            contentList35="Multiple Auction Types"
            contentList36="Auction Insights"
            tabContentTitle44="Freight Management Software"
            contentList41="Shipment Booking and Execution"
            contentList42="Rate Management"
            contentList43="Transportation Planning and Optimization"
            contentList44="Real-time Tracking and Visibility"
            contentList45="Documentation and Compliance"
            contentList46="Carrier Collaboration"
          />
        </div>
      </section>
      <section className="mouseScrollingSliderSection">
        <ScrollingSlide/>
      </section>
      <section
      className="mouseScrollingSliderSection"
      id="mouseScrollingSliderSection"
    >
      <div className="container">
        <div className="sectionTitle">
          <h2>
            {" "}
            Work <span className="textGrade">Portfolio</span>
          </h2>
          <ExpertButton
            rText="Get Project Quote"
            rName="rAnimeBtn qouteBtn"
          />
        </div>
      </div>
      <ScrollingSlide
        scrollSliderImg1={mouseSlide1}
        scrollSliderImg2={mouseSlide2}
        scrollSliderImg3={mouseSlide3}
        scrollSliderImg4={mouseSlide4}
        
      />
    </section>
      <section className="IndusExpertSection">
        <IndusExpert
          cName="Version Next Technologies"
          cSecTitle="Why Choose Version Next Technologies as Your Logistics Software Solution and Branding Partner?"
          profileName="Mr. Sanyogg Shelar"
            designation="Buisness Head"
            cardTitle1="Expertise and Innovation"
            cardData1="Access the best minds in the IoT industry and harness the power of innovative technologies to bring your ideas to life."
            cardTitle2="Customized Solutions"
            cardData2="We don't believe in one-size-fits-all solutions. Our team tailors each IoT project to suit your specific business needs and requirements."
            cardTitle3="Timely Delivery"
            cardData3="We understand the importance of time-to-market. Version Next is committed to delivering your IoT solution within agreed timelines without compromising on quality."
            cardTitle4="Transparent Communication"
            cardData4="Stay updated throughout the development process with our transparent and efficient communication channels."
            profileImg={sanyogprofileImg}
            facebookLink="https://profiles.wordpress.org/codexdemon/"
            facebookIcon="fab fa-wordpress"
            twiiterLink="https://twitter.com/sanyog"
            twitterIcon="fab fa-twitter-square"
            linkdinLink="https://www.linkedin.com/in/sanyogs"
            linkdinIcon="fab fa-linkedin"
        />
      </section>
      <section className="whatMakesSection" id="whatMakesSection">
        <DevDiff
          float1={float1}
          float2={float2}
          Char1="What Makes"
          Char2="us"
          Char3="Different?"
          diffDescription="What sets us apart is our expertise, customization, collaboration, creativity, results-driven approach, and customer-centric focus. We have a highly skilled team, personalized solutions, open communication, innovative ideas, a focus on measurable results, and a dedication to customer satisfaction. These factors combine to deliver outstanding outcomes and exceptional experiences for our clients."
        />
      </section>
    </section>
  );
}

export default Logistics;
