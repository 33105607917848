import React from "react";
import DevelopBannerSection from "../components/Homepage/DevelopPageComponents/DevelopBannerSection";
import Industries from "../components/Homepage/IndustriesSection/Industries";
import VntWork from "../components/ServicesPageComponents/VntWork";
import DevDiff from "../components/Homepage/DevelopPageComponents/DevDiff";
import IndusExpert from "../components/IndustriesPageComponents/IndusExpert";
import BlogSection from "../components/BlogPosts/BlogSection";
import {
  biImg1,
  biImg2,
  block18,
  block19,
  block20,
  block21,
  block22,
  block23,
  float1,
  float2,
  sanyogprofileImg,
} from "../components";
import IotProcessBlock from "../components/ServicesPageComponents/IotProcessBlock";
function Analytics() {
  return (
    <>
      <div className="devlopPage" id="devlopPage">
        <div className="devTopSection bInBg">
          <DevelopBannerSection
            line1="We Offer Comprehensive"
            line2="BI & Analytics "
            line3="Solutions"
            BannerPara="In today's data-driven world, making informed decisions is critical for business success. At Version Next Technologies, we offer comprehensive Business Intelligence (BI) & Analytics Solutions that empower organizations to harness the full potential of their data."
          />
          <div className="shapeSvg">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15002 2408">
              <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <path
                  className="fil0"
                  d="M14999.66 160.41l0 2250.5 -15002 0 0 -2408.48c4.48,15.46 34.05,38.31 47.09,51.36 18.3,18.3 33.31,33.31 51.61,51.61l377.15 322.94c339.34,254.69 723.38,451.6 1129.12,576.25 740.24,227.38 1518.28,209.44 2250.74,-34.78 297.55,-99.2 684.02,-289.57 933.07,-476.11 87.62,-65.63 227.27,-165.69 307.34,-240.17 31.42,-29.23 114.35,-99.39 131.22,-124.58 20,4.66 99.59,47.55 124.17,59.83 42.41,21.2 81.55,40.72 122.67,61.33l125.61 58.39c133.82,59.52 249.76,112.63 391.76,169.22 238.34,95 587.91,216.82 832.54,284.94 200.63,55.86 382.28,106.97 590.69,154.3 1557.59,353.7 3775.68,425.94 5382.01,17.95 25.54,-6.48 49.29,-10.39 73.59,-16.17l373.43 -93.31c198.88,-54.19 343.18,-97.87 536.61,-159.02l312.66 -109.2c47.88,-17.88 90.5,-33.57 136.53,-51.96 82.88,-33.12 316.41,-125.5 392.57,-163.92l128.78 -59.7c23.6,-10.77 225.99,-115.13 251.04,-121.22z"
                />
              </g>
            </svg>
          </div>
        </div>
        <section className="wntWorkSection iotProcessSection digiTransform">
          <span className="elm1 elm"></span>
          <span className="elm2 elm"></span>
          <span className="elm3 elm"></span>
          <span className="elm4 elm"></span>
          <div className="container">
            <VntWork
              subText="Our Business Intelligence & Analytics"
              mainText="Services"
              paratext="At Version Next Technologies, we believe that every organization's data journey is unique. As such, we offer tailor-made BI & Analytics Solutions that align with your business goals, user needs, and data sources."
            />
            <div className="iotPrecessBlock">
              <div className="row justify-content-center">
                <IotProcessBlock
                  imgSrc={block18}
                  title="Data Collection and Integration"
                  description="The foundation of effective BI and analytics is accurate and comprehensive data collection. We help organizations gather data from various sources, including structured and unstructured data, and integrate it seamlessly into a centralized repository. "
                />
                <IotProcessBlock
                  imgSrc={block19}
                  title="Data Warehousing"
                  description="Our experts design and implement robust data warehousing solutions that efficiently store and organize vast amounts of data. With scalable and secure data warehousing, organizations can quickly retrieve and analyze data, facilitating data-driven decision-making at all levels."
                />
                <IotProcessBlock
                  imgSrc={block20}
                  title="Data Analysis and Reporting"
                  description="Version Next Technologies offers advanced data analysis and reporting capabilities. Our team employs cutting-edge tools and techniques to uncover valuable patterns, trends, and correlations within your data. "
                />
                <IotProcessBlock
                  imgSrc={block21}
                  title="Data Mining and Predictive Analytics"
                  description="Stay ahead of the competition with our data mining and predictive analytics services. By leveraging historical data and employing predictive models, we empower organizations to anticipate future trends, identify potential opportunities, and mitigate risks effectively."
                />
                <IotProcessBlock
                  imgSrc={block22}
                  title="Performance Management"
                  description="Version Next Technologies emphasizes performance management as a crucial aspect of BI and analytics. We provide comprehensive dashboards and scorecards that monitor key performance indicators (KPIs) in real-time, enabling organizations to track progress and take timely corrective actions."
                />
                <IotProcessBlock
                  imgSrc={block23}
                  title="Real-time Analytics"
                  description="In the fast-paced business landscape, real-time insights are invaluable. Our real-time analytics solutions ensure that organizations can access critical information instantaneously, facilitating swift and data-driven decision-making."
                />
              </div>
            </div>
          </div>
        </section>
        <section id="industriesSection" className="industriesSection">
          <div className="rightTop"></div>
          <Industries
            char1="Our"
            char2="Services"
            char3="In"
            char4="Industries"
            secPara="We have honed our skills in developing advanced web platforms,
        mobile applications, and intricate systems that align with the
        latest industry trends."
            icon1="health_and_safety"
            cardTitle1="Health and Life Sciences"
            cardDesc1="EHR & EMR, Patient Portals software development services
          for institutions maintaining physical and mental
          well-being: medical, telemedicine."
            cardLink1="#"
            icon2="school"
            cardTitle2="Education"
            cardDesc2="Education app development services provided by Version
          Next empower learning experience for students, teachers,
          and administrations."
            cardLink2="#"
            icon3="account_balance"
            cardTitle3="FinTech"
            cardDesc3="Financial software development services that help automate
          processes, improve transaction security, and put the
          latest technologies for your business."
            cardLink3="#"
            icon4="volunteer_activism"
            cardTitle4="Insurance"
            cardDesc4="Insurance software development services that automate
          processes, improve data security, and help your business
          benefit from the latest technologies."
            cardLink4="#"
            icon5="inventory"
            cardTitle5="Supply Chain & Logistics"
            cardDesc5="We ensure consistent development, scaling, and high
          performance products for supply chain & logistics
          management to meet your corporate goals."
            cardLink5="#"
            icon6="storefront"
            cardTitle6="Digital Retail"
            cardDesc6="Solutions counting customer’s business processes,
          Increasing the performance of customer’s retail software
          by automating manual processes."
            cardLink6="#"
          />
          <div className="leftBtm"></div>
        </section>
        <section id="clientAssists" className="clientAssists digiAssist">
          <div className="container">
            <div className="sectionTitle">
              <h2>
                Key Considerations for{" "}
                <span className="textGrade">BI & Analytics Solutions</span>
              </h2>
              <p>&nbsp;</p>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="assistDataWrap">
                  <h5>Data Sources</h5>
                  <p>
                    Version Next Technologies assists businesses in identifying
                    and integrating data sources that matter most to their
                    operations. From internal systems to external data streams,
                    we optimize data acquisition to ensure the most relevant
                    insights.
                  </p>
                </div>
                <div className="assistDataWrap">
                  <h5>Business Goals</h5>
                  <p>
                    Our approach to BI and analytics is always aligned with your
                    business goals. We tailor our solutions to address your
                    specific challenges and leverage data to drive success in
                    the areas that matter most to your organization.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="assistImgWrap">
                  <img src={biImg1} alt="Business Goals" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="assistDataWrap">
                  <h5>User Needs</h5>
                  <p>
                    Understanding the unique needs of end-users is paramount to
                    our approach. We design intuitive and user-friendly
                    dashboards and reports, ensuring that decision-makers have
                    easy access to the information they need to make impactful
                    decisions.
                  </p>
                </div>
                <div className="assistDataWrap">
                  <h5>Scalability</h5>
                  <p>
                    We design scalable BI and analytics solutions that can grow
                    with your organization. Whether you are a startup or an
                    established enterprise, our services cater to your evolving
                    data requirements.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="assistImgWrap">
                  <img src={biImg2} alt="Data Sources" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whatMakesSection lessPad" id="whatMakesSection">
          <DevDiff
            float1={float1}
            float2={float2}
            Char1="Partner with"
            Char2="VNT"
            Char3="for BI & Analytics Solutions"
            diffDescription="As you step into the world of IoT, Version Next Technologies is your trusted companion. We offer end-to-end IoT development services, ensuring a seamless journey from ideation to deployment and beyond. Embrace innovation, maximize efficiency, and unleash the potential of IoT with Version Next Technologies by your side. "
            diffDescription2="Reach out to us today to embark on a transformative IoT journey that will shape the future of your business."
          />
        </section>
        <section className="IndusExpertSection">
          <IndusExpert
            cName="Version Next Technologies"
            cSecTitle="Version Next Technologies - Your BI & Analytics Partner"
            profileName="Mr. Sanyogg Shelar"
            designation="Buisness Head"
            cardTitle1="Comprehensive Assessment"
            cardData1="We begin by conducting a thorough assessment of your organization's current IT infrastructure, processes, and culture."
            cardTitle2="Tailored Strategy"
            cardData2="Armed with the insights from the assessment, we develop a tailored digital transformation strategy that aligns with your business objectives. "
            cardTitle3="Customer-Obsession"
            cardData3="Our unwavering focus on delivering exceptional customer experiences sets us apart, as we go above and beyond to meet and exceed client expectations."
            cardTitle4="Transparent Communication"
            cardData4="Stay updated throughout the development process with our transparent and efficient communication channels."
            profileImg={sanyogprofileImg}
            facebookLink="https://profiles.wordpress.org/codexdemon/"
            facebookIcon="fab fa-wordpress"
            twiiterLink="https://twitter.com/sanyog"
            twitterIcon="fab fa-twitter-square"
            linkdinLink="https://www.linkedin.com/in/sanyogs"
            linkdinIcon="fab fa-linkedin"
          />
        </section>
        <BlogSection />
      </div>
    </>
  );
}

export default Analytics;
