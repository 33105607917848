import React from "react";
import DevelopBannerSection from "../components/Homepage/DevelopPageComponents/DevelopBannerSection";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HealthSliderCard from "../components/HealthcarePageComponent/HealthSliderCard";
import IndusExpert from "../components/IndustriesPageComponents/IndusExpert";
import { Accodion } from "../components/Accordion/AccordionItem";
import FaqVideo from "../components/FaqVideo/FaqVideo";
import TransparentRoundedBtn from "../components/CTA/TransparentRoundedBtn";
import {
  float1,
  float2,
  mouseSlide10,
  mouseSlide11,
  mouseSlide12,
  mouseSlide14,
  mouseSlide15,
  mouseSlide9,
  vinayakprofileImg,
} from "../components";
import DevDiff from "../components/Homepage/DevelopPageComponents/DevDiff";
import HealthCareTabSection from "../components/HealthcarePageComponent/HealthCareTabSection";
import HealthcareTabs from "../components/Tabs/HealthcareTabs";
import ExpertButton from "../components/CTA/ExpertButton";
import ScrollingSlide from "../components/MouseScrollingComponent/ScrollingSlide";
function FinTech() {
  var settings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: true,
    speed: 800,
    adaptiveHeight: false,
    infinite: true,
    useTransform: true,
    cssEase: "cubic-bezier(0.77, 0, 0.18, 1)",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section id="healthCare" className="healthCare fintTechBg">
      <div className="devTopSection">
        <DevelopBannerSection
          line1="We Provide"
          line2="FinTech Software"
          line3="Development Services"
          BannerPara="Creating healthcare apps to provide patients with better services. Making custom software to optimize workflows at medical facilities. Digitizing healthcare to improve its interoperability."
        />
        <div className="shapeSvg">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15002 2408">
            <g id="Layer_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <path
                className="fil0"
                d="M14999.66 160.41l0 2250.5 -15002 0 0 -2408.48c4.48,15.46 34.05,38.31 47.09,51.36 18.3,18.3 33.31,33.31 51.61,51.61l377.15 322.94c339.34,254.69 723.38,451.6 1129.12,576.25 740.24,227.38 1518.28,209.44 2250.74,-34.78 297.55,-99.2 684.02,-289.57 933.07,-476.11 87.62,-65.63 227.27,-165.69 307.34,-240.17 31.42,-29.23 114.35,-99.39 131.22,-124.58 20,4.66 99.59,47.55 124.17,59.83 42.41,21.2 81.55,40.72 122.67,61.33l125.61 58.39c133.82,59.52 249.76,112.63 391.76,169.22 238.34,95 587.91,216.82 832.54,284.94 200.63,55.86 382.28,106.97 590.69,154.3 1557.59,353.7 3775.68,425.94 5382.01,17.95 25.54,-6.48 49.29,-10.39 73.59,-16.17l373.43 -93.31c198.88,-54.19 343.18,-97.87 536.61,-159.02l312.66 -109.2c47.88,-17.88 90.5,-33.57 136.53,-51.96 82.88,-33.12 316.41,-125.5 392.57,-163.92l128.78 -59.7c23.6,-10.77 225.99,-115.13 251.04,-121.22z"
              />
            </g>
          </svg>
        </div>
      </div>
      <div className="healthServicesSliderWrap">
        <div className="container">
          <div className="row sectionFlexHeading">
            <div className="col-md-6 col-12">
              <div className="sectionTitle">
                <h2>Fintech Software Services We Offer</h2>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <p className="sectionPara">
                FinTech software development services for all industry
                institutions: medical, telemedicine and remote care, maintaining
                physical and mental well-being
              </p>
            </div>
          </div>
          <div className="healthServicesSlider">
            <Slider {...settings} className="slider-with-gap">
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Custom Software Development"
                  cardPara="Studying the customer’s business process to develop bespoke solutions, including SaaS. Compliance with industry regulations (GDPR, HIPAA, PIPEDA, and more)."
                  cardLink=""
                  btnName="Get consultation"
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Software Modernization"
                  cardPara="Reengineering the existing solutions to meet the latest healthcare and telehealth standards in terms of security, usability, and interoperability."
                  cardLink=""
                  btnName="Leave a request"
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Third Party Integrations"
                  cardPara="Aligning custom-built software with internal systems, EHR, EMR, Patient Portals, e-RX services for prescription solutions, etc. Integrations with interactive streaming platforms for Telehealth solutions."
                  cardLink=""
                  btnName="Order an estimate"
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Digitalization and Optimization"
                  cardPara="Increasing the performance of healthcare and telemedicine software by automating manual processes."
                  cardLink=""
                  btnName="Share your idea"
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <section id="healthCareTabSection">
        <div className="container">
          <HealthCareTabSection
            tabSectionTitle1="FinTech"
            tabSectionTitle2="Software Solutions"
            tabSectionTitle3="We Provide"
            tabSectionPara="Custom healthcare software development standalone solutions and major
          modules for larger systems. VNT's solutions optimize services
          and workflows, enhancing interaction between providers, their
          partners/clients, and patients."
          />
          <HealthcareTabs
            tabTitle1="Electronic Trading Platforms"
            tabTitle2="Blockchain and Cryptocurrency"
            tabTitle3="Cybersecurity"
            tabTitle4="Digital Payments"
            tabContentTitle11="Electronic Trading Platforms"
            contentList11="Trading operations simplification"
            contentList12="Liquidity analysis"
            contentList13="Algorithmic trading"
            tabContentTitle22="Blockchain and Cryptocurrency"
            contentList21="Cryptocurrency exchange and trading apps"
            contentList22="Cryptocurrency wallet"
            contentList23="Smart contract"
            contentList24="DeFi, DEXs, and auctions"
            tabContentTitle33="Cybersecurity"
            contentList31="Security testing"
            contentList32="Malware & fraud protection"
            contentList33="Vulnerability assessment"
            contentList34="RegTech"
            tabContentTitle44="Digital Payments"
            contentList41="Online payment systems"
            contentList42="Digital wallets"
            contentList43="POS software"
            contentList44="Recurring billing"
            contentList45="Payments security"
            contentList46="B2B payments"
            contentList47="Mobile payments"
            contentList48="P2P transfers"
          />
        </div>
      </section>
      <section
        className="mouseScrollingSliderSection"
        id="mouseScrollingSliderSection"
      >
        <div className="container">
          <div className="sectionTitle">
            <h2>
              {" "}
              Work <span className="textGrade">Portfolio</span>
            </h2>
            <ExpertButton
              rText="Get Project Quote"
              rName="rAnimeBtn qouteBtn"
            />
          </div>
        </div>
        <ScrollingSlide
          scrollSliderImg1={mouseSlide9}
          scrollSliderImg2={mouseSlide10}
          scrollSliderImg3={mouseSlide11}
          scrollSliderImg4={mouseSlide12}
          scrollSliderImg6={mouseSlide14}
          scrollSliderImg7={mouseSlide15}
        />
      </section>
      <section className="IndusExpertSection">
        <IndusExpert
          cName="Version Next Technologies"
          cSecTitle="Why Hire Version Next Technologies as Branding Partner?"
          profileName="Mr. Vinayak Parab"
          designation="Buisness Head"
          cardTitle1="Innovation"
          cardData1="We brings innovative solutions that leverage cutting-edge technologies to drive business growth and stay ahead of the competition."
          cardTitle2="Integrity"
          cardData2="We uphold the highest standards of integrity, ensuring transparency, honesty, and ethical practices in all our interactions with clients."
          cardTitle3="Customer-Obsession"
          cardData3="Our unwavering focus on delivering exceptional customer experiences sets us apart, as we go above and beyond to meet and exceed client expectations."
          cardTitle4="Quality"
          cardData4="We are committed to delivering top-notch quality in all our services, adhering to industry best practices and rigorous quality control processes to ensure excellence."
          profileImg={vinayakprofileImg}
          linkdinLink="http://linkedin.com/in/vinayak-parab-2a7a8615"
          linkdinIcon="fab fa-linkedin"
        />
      </section>
      <section className="whatMakesSection" id="whatMakesSection">
        <DevDiff
          float1={float1}
          float2={float2}
          Char1="What Makes"
          Char2="us"
          Char3="Different?"
          diffDescription="What sets us apart is our expertise, customization, collaboration, creativity, results-driven approach, and customer-centric focus. We have a highly skilled team, personalized solutions, open communication, innovative ideas, a focus on measurable results, and a dedication to customer satisfaction. These factors combine to deliver outstanding outcomes and exceptional experiences for our clients."
        />
      </section>
      <section className="commonFaq">
        <div className="container">
          <div className="sectionTitle">
            <h2>
              Frequently Asked <span className="textGrade">Questions</span>
            </h2>

            <p>
              Singling out common points and possible difficulties which can be
              encountered in EHR, EMR, and Patient Portal software development
            </p>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-6 col-12">
              <Accodion />
            </div>
            <div className="col-md-12 col-lg-6 col-12">
              <FaqVideo />
            </div>
          </div>
          <div className="readMoreBtnWrap">
            <TransparentRoundedBtn
              rText="Read More"
              rLink="/faqs"
              rName="rAnimeBtn readMoretBtn"
            />
          </div>
        </div>
      </section>
    </section>
  );
}

export default FinTech;
