import React from "react";
import DevelopBannerSection from "../components/Homepage/DevelopPageComponents/DevelopBannerSection";
import Industries from "../components/Homepage/IndustriesSection/Industries";
import VntWork from "../components/ServicesPageComponents/VntWork";
import DevDiff from "../components/Homepage/DevelopPageComponents/DevDiff";
import { Accodion } from "../components/Accordion/AccordionItem";
import FaqVideo from "../components/FaqVideo/FaqVideo";
import TransparentRoundedBtn from "../components/CTA/TransparentRoundedBtn";
import IndusExpert from "../components/IndustriesPageComponents/IndusExpert";
import BlogSection from "../components/BlogPosts/BlogSection";
import {
  assistImg1,
  block1,
  block2,
  block3,
  block4,
  block5,
  block6,
  devOps1,
  float1,
  float2,
  sanyogprofileImg,
} from "../components";
import IotProcessBlock from "../components/ServicesPageComponents/IotProcessBlock";
function IoTDev() {
  return (
    <>
      <div className="devlopPage" id="devlopPage">
        <div className="devTopSection iotBg">
          <DevelopBannerSection
            line1="Unlock Unprecedented"
            line2="Business Growth"
            line3="with our IoT"
            BannerPara="Welcome to the cutting-edge world of Internet of Things (IoT) development with Version Next Technologies. As a leading provider of top-notch IoT services, we bring you innovative solutions that bridge the gap between physical and digital realms."
          />
          <div className="shapeSvg">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15002 2408">
              <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <path
                  className="fil0"
                  d="M14999.66 160.41l0 2250.5 -15002 0 0 -2408.48c4.48,15.46 34.05,38.31 47.09,51.36 18.3,18.3 33.31,33.31 51.61,51.61l377.15 322.94c339.34,254.69 723.38,451.6 1129.12,576.25 740.24,227.38 1518.28,209.44 2250.74,-34.78 297.55,-99.2 684.02,-289.57 933.07,-476.11 87.62,-65.63 227.27,-165.69 307.34,-240.17 31.42,-29.23 114.35,-99.39 131.22,-124.58 20,4.66 99.59,47.55 124.17,59.83 42.41,21.2 81.55,40.72 122.67,61.33l125.61 58.39c133.82,59.52 249.76,112.63 391.76,169.22 238.34,95 587.91,216.82 832.54,284.94 200.63,55.86 382.28,106.97 590.69,154.3 1557.59,353.7 3775.68,425.94 5382.01,17.95 25.54,-6.48 49.29,-10.39 73.59,-16.17l373.43 -93.31c198.88,-54.19 343.18,-97.87 536.61,-159.02l312.66 -109.2c47.88,-17.88 90.5,-33.57 136.53,-51.96 82.88,-33.12 316.41,-125.5 392.57,-163.92l128.78 -59.7c23.6,-10.77 225.99,-115.13 251.04,-121.22z"
                />
              </g>
            </svg>
          </div>
        </div>
        <section className="wntWorkSection iotProcessSection">
          <span className="elm1 elm"></span>
          <span className="elm2 elm"></span>
          <span className="elm3 elm"></span>
          <span className="elm4 elm"></span>
          <div className="container">
            <VntWork
              subText="IoT Development"
              mainText="Process"
              paratext="We employ top-notch teams and cutting-edge technologies to guarantee a smooth, comprehensive software development process. As a full-cycle product development service provider, we prioritise building, testing, and deploying your product to align with your business needs, regardless of its size or industry."
            />
            <div className="iotPrecessBlock">
              <div className="row justify-content-center">
                <IotProcessBlock
                  imgSrc={block1}
                  title="Ideation and Consultation"
                  description="At Version Next, we believe in laying a strong foundation for success. We collaborate with our clients to understand their unique requirements and business goals. Our expert team of IoT consultants will work closely with you to ideate, strategize, and outline a tailor-made IoT solution that aligns with your objectives."
                />
                <IotProcessBlock
                  imgSrc={block2}
                  title="Prototyping and Proof of Concept"
                  description="Our iterative approach includes creating prototypes and proofs of concept (POCs) to validate ideas before diving into full-scale development. This step ensures that the envisioned IoT solution is both technically feasible and capable of delivering the desired results."
                />
                <IotProcessBlock
                  imgSrc={block3}
                  title="Custom Development"
                  description="Once the POC is approved, our skilled IoT developers get to work, crafting a bespoke solution that meets your specific needs. We leverage cutting-edge technologies and best practices to create robust and scalable IoT systems that integrate seamlessly into your existing infrastructure."
                />
                <IotProcessBlock
                  imgSrc={block4}
                  title="Testing and Quality Assurance"
                  description="Quality is at the core of everything we do. Before deployment, our dedicated QA team conducts rigorous testing to identify and rectify any issues. This process ensures that your IoT solution functions flawlessly and offers a seamless user experience."
                />
                <IotProcessBlock
                  imgSrc={block5}
                  title="Deployment and Integration"
                  description="With the development and testing phases completed, we deploy the IoT solution within your ecosystem. Our experts handle the integration process to ensure a smooth transition and minimize disruption to your operations."
                />
                <IotProcessBlock
                  imgSrc={block6}
                  title="Maintenance and Support"
                  description="Our commitment to your success doesn't end with deployment. We provide ongoing maintenance and support services to keep your IoT system up and running optimally. Regular updates and proactive monitoring ensure that your solution remains secure and future-proof."
                />
              </div>
            </div>
          </div>
        </section>
        <section id="industriesSection" className="industriesSection">
          <div className="rightTop"></div>
          <Industries
            char1="Our"
            char2="Services"
            char3="In"
            char4="Industries"
            secPara="We have honed our skills in developing advanced web platforms,
        mobile applications, and intricate systems that align with the
        latest industry trends."
            icon1="health_and_safety"
            cardTitle1="Health and Life Sciences"
            cardDesc1="EHR & EMR, Patient Portals software development services
          for institutions maintaining physical and mental
          well-being: medical, telemedicine."
            cardLink1="/healthcare"
            icon2="school"
            cardTitle2="Education"
            cardDesc2="Education app development services provided by Version
          Next empower learning experience for students, teachers,
          and administrations."
            cardLink2="/education-software-development-company"
            icon3="account_balance"
            cardTitle3="FinTech"
            cardDesc3="Financial software development services that help automate
          processes, improve transaction security, and put the
          latest technologies for your business."
            cardLink3="/fin-tech"
            icon4="volunteer_activism"
            cardTitle4="Insurance"
            cardDesc4="Insurance software development services that automate
          processes, improve data security, and help your business
          benefit from the latest technologies."
            cardLink4="/insurance"
            icon5="inventory"
            cardTitle5="Supply Chain & Logistics"
            cardDesc5="We ensure consistent development, scaling, and high
          performance products for supply chain & logistics
          management to meet your corporate goals."
            cardLink5="/logistics-software-solutions"
            icon6="storefront"
            cardTitle6="Digital Retail"
            cardDesc6="Solutions counting customer’s business processes,
          Increasing the performance of customer’s retail software
          by automating manual processes."
            cardLink6="/digital-retail"
          />
          <div className="leftBtm"></div>
        </section>
        <section id="clientAssists" className="clientAssists">
          <div className="container">
            <div className="sectionTitle">
              <h2>
                How Version Next{" "}
                <span className="textGrade">Assists Clients</span>
              </h2>
              <p>
                Singling out common points and possible difficulties which can
                be encountered in EHR, EMR, and Patient Portal software
                development
              </p>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="assistDataWrap">
                  <h5>Tailored Solutions</h5>
                  <p>
                    We understand that each client has unique requirements. Our
                    team works closely with you to comprehend your business
                    needs and create IoT solutions that address your pain points
                    effectively.
                  </p>
                </div>
                <div className="assistDataWrap">
                  <h5>Domain Expertise</h5>
                  <p>
                    Version Next Technologies boasts a team of seasoned
                    professionals with diverse expertise across various
                    industries. We leverage this knowledge to develop IoT
                    solutions that deliver real value and a competitive edge.
                  </p>
                </div>
                <div className="assistDataWrap">
                  <h5>Seamless Integration</h5>
                  <p>
                    Whether you need to enhance an existing IoT ecosystem or
                    start from scratch, Version Next ensures that the new
                    solution seamlessly integrates with your current
                    infrastructure, applications, and data sources.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="assistImgWrap">
                  <img src={assistImg1} alt="VNT Client Assists" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="assistDataWrap">
                  <h5>Scalability and Future-Readiness</h5>
                  <p>
                    Our IoT solutions are designed with scalability in mind. As
                    your business grows, our systems can effortlessly
                    accommodate increased demand and new functionalities.
                  </p>
                </div>
                <div className="assistDataWrap">
                  <h5>Security and Data Privacy</h5>
                  <p>
                    We prioritize the security and privacy of your data. Our IoT
                    solutions implement robust security measures to safeguard
                    sensitive information and maintain compliance with relevant
                    regulations.
                  </p>
                </div>
                <div className="assistDataWrap">
                  <h5>Continuous Improvement and Innovation</h5>
                  <p>
                    At Version Next Technologies, we foster a culture of
                    continuous improvement and innovation. Our dedicated R&D
                    team constantly explores emerging technologies, industry
                    trends, and best practices to enhance our IoT development
                    services continually.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="assistImgWrap">
                  <img src={devOps1} alt="VNT Client Assists" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whatMakesSection" id="whatMakesSection">
          <DevDiff
            float1={float1}
            float2={float2}
            Char1="What Makes"
            Char2="us"
            Char3="Different?"
            diffDescription="As you step into the world of IoT, Version Next Technologies is your trusted companion. We offer end-to-end IoT development services, ensuring a seamless journey from ideation to deployment and beyond. Embrace innovation, maximize efficiency, and unleash the potential of IoT with Version Next Technologies by your side. "
            diffDescription2="Reach out to us today to embark on a transformative IoT journey that will shape the future of your business."
          />
        </section>
        <section className="IndusExpertSection">
          <IndusExpert
            cName="Version Next Technologies"
            cSecTitle="When you choose Version Next Technologies as your IoT development partner, you can expect"
            profileName="Mr. Sanyogg Shelar"
            designation="Buisness Head"
            cardTitle1="Expertise and Innovation"
            cardData1="Access the best minds in the IoT industry and harness the power of innovative technologies to bring your ideas to life."
            cardTitle2="Customized Solutions"
            cardData2="We don't believe in one-size-fits-all solutions. Our team tailors each IoT project to suit your specific business needs and requirements."
            cardTitle3="Timely Delivery"
            cardData3="We understand the importance of time-to-market. Version Next is committed to delivering your IoT solution within agreed timelines without compromising on quality."
            cardTitle4="Transparent Communication"
            cardData4="Stay updated throughout the development process with our transparent and efficient communication channels."
            profileImg={sanyogprofileImg}
            facebookLink="https://profiles.wordpress.org/codexdemon/"
            facebookIcon="fab fa-wordpress"
            twiiterLink="https://twitter.com/sanyog"
            twitterIcon="fab fa-twitter-square"
            linkdinLink="https://www.linkedin.com/in/sanyogs"
            linkdinIcon="fab fa-linkedin"
          />
        </section>
        <section className="commonFaq">
          <div className="container">
            <div className="sectionTitle">
              <h2>
                Frequently Asked <span className="textGrade">Questions</span>
              </h2>

              <p>
                Singling out common points and possible difficulties which can
                be encountered in EHR, EMR, and Patient Portal software
                development
              </p>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-6 col-12">
                <Accodion />
              </div>
              <div className="col-md-12 col-lg-6 col-12">
                <FaqVideo />
              </div>
            </div>
            <div className="readMoreBtnWrap">
              <TransparentRoundedBtn
                rText="Read More"
                rLink="/faqs"
                rName="rAnimeBtn readMoretBtn"
              />
            </div>
          </div>
        </section>
        <BlogSection />
      </div>
    </>
  );
}

export default IoTDev;
