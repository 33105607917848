import React from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useLayoutEffect, useRef } from "react";
import { op1, op11, op12, op2, op3, op4, op5, op6} from "..";
import ExpertButton from "../CTA/ExpertButton";
// console.log(scroll)
gsap.registerPlugin(ScrollTrigger);
function OurProcess() {
  const component = useRef();
  const slider = useRef();
  if (window.innerWidth > 834) {
    useLayoutEffect(() => {
      let ctx = gsap.context(() => {
        let panels = gsap.utils.toArray(".panel");
        gsap.to(panels, {
          xPercent: -100 * (panels.length - 1),
          ease: "none",
          scrollTrigger: {
            trigger: slider.current,
            pin: true,
            scrub: 0,
            end: () => "+=" + slider.current.offsetWidth,
            markers: false,
          },
        });
      }, component);
      return () => ctx.revert();
    });
  }
  return (
    <div className="our_Container">
      <div className="ourProcessPannelWrap" ref={component}>
        <div className="panelWrap">
          <div className="block horscroll-block" id="process">
            <div className="kolbasa" ref={slider}>
              <div className="panel">
                <div className="inner-hor-block centered one ml_110">
                  <div className="ihb-content">
                    <div className="hs-info-block hs-start">
                      <h3 className="hs-title">Our Processes</h3>
                      <p className="hs-small-text w-arrow">
                        {" "}
                        Analysis. Planning Stage. Design.
                        <br /> Development &amp;&nbsp;QA. Launch.
                        <br /> Maintenance and Growth.
                      </p>
                    </div>
                    <img
                      className="hs-2"
                      src={op11}
                      alt="our process"
                    />
                  </div>
                </div>
              </div>
              <div className="panel">
                <div className="inner-hor-block centered one">
                  <div className="ihb-content">
                    <div className="hs-info-block hs-start w40">
                      <h3 className="hs-title">Analysis</h3>
                      <p className="hs-small-text sc-undertitle">
                        Our team conduct a research of:
                      </p>
                      <p className="hs-small-text">
                        Product goals and strategy are analysed. Project
                        structure and roles are defined. Optimal methodology and
                        approach for robust development are determined.
                      </p>
                    </div>

                    <img
                      className="hs-22 mobhide"
                      src={op1}
                      alt="Analysis"
                    />
                    <img
                      className="hs-22 mobshow"
                      src={op12}
                      alt="Analysis"
                    />
                  </div>
                </div>
              </div>
              <div className="panel">
                <div className="inner-hor-block centered one">
                  <div className="ihb-content">
                    <div className="hs-info-block hs-start">
                      <h3 className="hs-title">Planning Stage</h3>
                      <p className="hs-small-text sc-undertitle">
                        At this stage we develop:
                      </p>
                      <p className="hs-small-text">
                        The project's detailed structure is outlined, including
                        user-story descriptions of functionality and UX design
                        considerations. An accurate development
                        estimation is provided.
                      </p>
                    </div>
                    <img
                      className="hs-3"
                      alt="Planning Stage"
                      src={op2}
                    />
                  </div>
                </div>
              </div>
              <div className="panel">
                <div className="inner-hor-block centered one">
                  <div className="ihb-content">
                    <div className="hs-info-block hs-start">
                      <h3 className="hs-title">Design</h3>
                      <p className="hs-small-text sc-undertitle">
                        This step allow us to:
                      </p>
                      <p className="hs-small-text">
                        Product requirements are explored, research data is
                        leveraged, a prototype is built, a design concept is
                        created, and the design is tested for optimal
                        user experience.
                      </p>
                    </div>
                    <img className="hs-3" alt="Design" src={op3} />
                  </div>
                </div>
              </div>
              <div className="panel">
                <div className="inner-hor-block centered one ">
                  <div className="ihb-content h-height">
                    <div className="hs-info-block hs-start w40">
                      <h3 className="hs-title">Development &amp;&nbsp;QA</h3>

                      <p className="hs-small-text">
                        We adapt our development process to align with the
                        project's environment, ensuring an effective
                        methodology.
                      </p>
                      <p className="hs-small-text wm grey wmm">
                        Our primary focus is on conducting thorough QA testing
                        throughout the development phase, enabling us to deliver
                        high-quality products.
                      </p>
                      <p className="hs-small-text wm grey">
                        We maintain transparency throughout the entire process
                        and execution of our work.
                      </p>
                    </div>
                    <img
                      className="hs-5-new"
                      alt="Development and QA"
                      src={op4}
                    />
                  </div>
                </div>
              </div>
              <div className="panel">
                <div className="inner-hor-block centered one">
                  <div className="ihb-content">
                    <div className="hs-info-block hs-start w40">
                      <h3 className="hs-title">Launch</h3>
                      <p className="hs-small-text sc-undertitle">
                        Preparing for a Successful Launch
                      </p>
                      <p className="hs-small-text">
                        During this stage, all involved parties are prepared for
                        the public launch. We utilize a CI/CD approach to
                        deliver the product to end users efficiently
                        and effectively.
                      </p>
                    </div>
                    <img className="hs-6" alt="Launch" src={op5} />
                  </div>
                </div>
              </div>
              <div className="panel">
                <div className="inner-hor-block centered one">
                  <div className="ihb-content">
                    <div className="hs-info-block hs-start w36">
                      <h3 className="hs-title">Maintenance and Growth</h3>
                      <p className="hs-small-text sc-undertitle">
                        At this stage we focus on:
                      </p>
                      <p className="hs-small-text">
                        Providing Technical and Customer Support while Setting
                        Key Metrics for Critical Growth Indicators and Utilising
                        an Iterative Approach for Continuous Growth Strategy.
                      </p>
                      <ExpertButton rText="Talk To Experts" rName="rAnimeBtn expertBtn"/>
                    </div>
                    <img
                      className="hs-7-new posAbs1"
                      alt="Maintenance and Growth"
                      src={op6}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurProcess;
